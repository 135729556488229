import React, { Fragment } from 'react'
import { APCard, APImage, APText } from '.'
import { APColumn, APRow } from '../layout'
import { APPalette } from '../utils'
export interface APSectionMessageAction {
    label: String;
    onClick: () => void
}

export interface APSectionMessageProps {
    title?: string,
    description?: string,
    actions?: APSectionMessageAction[];
    /**
     * @default default
     */
    variant?: 'info' | 'warning' | 'default' | 'success' | 'negative';
    style?: React.CSSProperties;
    onClose?: () => void | Promise<void>;
}

export default function APSectionMessage({ title, description, style, actions, variant = "default", onClose }: APSectionMessageProps) {
    let backgroundColor = APPalette.white;
    let border = `1px solid `;
    let fontColor = APPalette['info-400'];
    switch (variant) {
        case 'info':
            backgroundColor = APPalette['info-100'];
            border += APPalette['info-200'];
            break;
        case 'warning':
            backgroundColor = APPalette['warning-100'];
            border += APPalette['warning-200'];
            // fontColor = APPalette['warning-400'];
            break;
        case 'success':
            backgroundColor = APPalette['positive-100'];
            border += APPalette['positive-200'];
            fontColor = APPalette['positive-400'];
            break;
        case 'negative':
            backgroundColor = APPalette['negative-100'];
            border += APPalette['negative-200'];
            fontColor = APPalette['negative-400'];
            break;
        default:
            border += APPalette['grey-200'];
    }

    let flagIcon = 'note';
    if (variant !== 'default') {
        flagIcon = 'megaphone'
    }

    return (
        <APCard style={{ borderRadius: '8px', padding: "12px", margin: "8px 0", ...style, backgroundColor, border }}>
            <APRow gap='8px' mainAxisAlignment='start' crossAxisAlignment='start'>
                <APRow mainAxisSize='min'>
                    <APImage src={`/icons/icon-${flagIcon}.svg`} width={24} height={24} />
                </APRow>
                <APColumn mainAxisAlignment='start' crossAxisAlignment='start' gap='8px'>
                    {
                        (title || onClose) &&
                        <APRow mainAxisAlignment='spaceBetween' gap='8px'>
                            {
                                title &&
                                <APText color={APPalette['grey-700']} variant='h3'>{title}</APText>
                            }
                            {
                                onClose &&
                                <APImage src={'/icons/icon-close.svg'} onClick={onClose} />
                            }
                        </APRow>
                    }
                    {
                        description &&
                        <APText color={APPalette['grey-600']} variant={'paragraph-small'} >
                            {description}
                        </APText>
                    }
                    {
                        actions && actions.length > 0 &&
                        <APRow gap="8px">
                            {
                                actions.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <APText variant='link-small' color={fontColor} style={{ textDecoration: 'underline' }} onClick={
                                                item.onClick
                                            }>
                                                {item.label}
                                            </APText>
                                            {
                                                index <= actions.length - 2 &&
                                                <APText color={fontColor}>
                                                    •
                                                </APText>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </APRow>
                    }
                </APColumn>
            </APRow >
        </APCard >
    )
}
