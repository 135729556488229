import { useState, } from "react"
import { APCard, APText } from "../elements"
import { APIcon } from "../icons/APIcon"
import { APRow, APSizedBox, APColumn } from "../layout"
import { APPalette } from "../utils"
import { APFormFieldDropdown } from "./APFormFieldDropdown"
import { APRiderItem } from "./APRadioRider"
export interface RiderProps {
    value: string;
    sumInsuredValue?: string;
}
interface APCheckboxRiderProps {
    riders: APRiderItem[],
    onChanged: (v: RiderProps[]) => void;
}

export function APCheckboxRider({
    riders,
    onChanged
}: APCheckboxRiderProps) {
    const [selectedRiders, setSelectedRiders] = useState<RiderProps[]>([])
    const [dropdownValue, setDropdownValue] = useState<string>()
    const handleChange = (value: string) => {
        const existingIndex = selectedRiders.findIndex(rider => rider.value === value);
        let updatedRiders;
        if (existingIndex === -1) {
            //selected dropdown value else initial value
            const selectedRiderItem = riders.find(item => item.value === value);
            var sumValue: string | undefined = selectedRiderItem?.dropDownItem?.dropDownItem?.find((dv) => dv.value == dropdownValue)?.value ?? riders.find(rider => rider.value === value)?.dropDownItem?.initialValue;
            updatedRiders = [...selectedRiders, {
                value, sumInsuredValue:
                    sumValue ?? undefined
            }];
        } else {
            updatedRiders = [...selectedRiders];
            updatedRiders.splice(existingIndex, 1);
        }
        setSelectedRiders(updatedRiders);
        onChanged(updatedRiders);
    };
    const handleDropdownChange = (riderValue: string, sumInsuredValue: string) => {
        const updatedRiders = selectedRiders.map(rider => {
            if (rider.value === riderValue) {
                return {
                    ...rider,
                    sumInsuredValue: sumInsuredValue
                };
            }
            return rider;
        });
        setSelectedRiders(updatedRiders);
        if (updatedRiders.some(rider => rider.value === riderValue)) {
            onChanged(updatedRiders);
        }
    };
    return <div>
        {
            riders.map((item, index) =>
                <>
                    <APCard key={item.value}
                        style={{
                            borderRadius: '8px', position: 'relative', border: `1px solid ${selectedRiders.some(i => i.value === item.value)
                                ? APPalette["brand-300"] : APPalette["grey-200"]}`
                        }}>
                        <APRow crossAxisAlignment="start" mainAxisAlignment='spaceBetween'>
                            <APRow mainAxisAlignment="start" crossAxisAlignment="start">
                                <APRow onClick={() => {
                                    handleChange(item.value)
                                }} mainAxisSize='min'>
                                    <APIcon icon={selectedRiders.some(i => i.value === item.value)
                                        ? 'checkCircle' : 'circleOutline'} size={20} color={selectedRiders.some(i => i.value === item.value)
                                            ? APPalette["brand-300"] : APPalette["grey-400"]} />
                                </APRow>
                                <APSizedBox width='8px' />
                                <APColumn crossAxisAlignment="start">
                                    <APText variant='h4'>
                                        {item?.label}
                                    </APText>
                                    <APSizedBox height="4px" />
                                    {
                                        typeof item.description == 'string'
                                            ?
                                            <APText variant='paragraph-small'>{item.description}</APText>
                                            :
                                            item.description?.map((description: string, index) => {
                                                return <APRow key={index} crossAxisAlignment="start">
                                                    <ul style={{ color: APPalette["grey-600"] }}>
                                                        <li><APText variant='paragraph-small'>{description}</APText></li>
                                                    </ul>
                                                </APRow>
                                            })
                                    }
                                    {
                                        item.dropDownItem != null &&
                                        <>
                                            <APSizedBox height="8px" />
                                            <APFormFieldDropdown
                                                hideCloseButton
                                                initialValue={item.dropDownItem.initialValue}
                                                style={{ width: '256px' }}
                                                onChanged={(value) => {
                                                    if (value != null) {
                                                        handleDropdownChange(item.value, value)
                                                        setDropdownValue(value)
                                                    }
                                                }
                                                }
                                                label={item.dropDownItem?.title ?? ''}
                                                items={item.dropDownItem?.dropDownItem ?? []} />
                                        </>
                                    }
                                </APColumn>
                            </APRow>
                            <APColumn crossAxisAlignment="end" style={{ maxWidth: '150px' }}>
                                <APText variant='paragraph-xsmall'>Premium</APText>
                                <APText variant="h3">  {item.amount >= 0 ? '+ ' : '- '}{item.amount.currency()}</APText>
                            </APColumn>
                        </APRow>
                    </APCard>
                    {
                        index < riders.length - 1 && <APSizedBox height="20px" />
                    }
                </>
            )
        }
    </div>
}

