import React, { useEffect, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { APClone } from "./Common";


let scrollMemory = new Map<string, number>();

export function APVirtualList({ itemCount, itemHeight, children, style, initialScrollOffset, scrollKey }: {
    itemCount: number;
    itemHeight: number;
    children: (inputs: { index: number; }) => React.ReactElement;
    scrollKey?: string;
    style?: React.CSSProperties;
    initialScrollOffset?: number;
}) {

    let scrollPos: number | undefined = undefined;
    if (scrollKey) {
        scrollPos = scrollMemory.get(scrollKey);
    }

    return (
        <div style={style}>
            <AutoSizer >
                {({ width, height }: { width: number, height: number }) => {
                    return <FixedSizeList
                        itemCount={itemCount}
                        itemSize={itemHeight}
                        key={scrollKey}
                        initialScrollOffset={scrollPos || initialScrollOffset}
                        onScroll={({ scrollOffset }) => {
                            if (scrollKey) {
                                scrollMemory.set(scrollKey, scrollOffset);
                            }
                        }}
                        height={height}
                        width={width}
                    >
                        {({ index, style }) => {
                            return <APClone style={style}>{children({ index })}</APClone>;
                        }}
                    </FixedSizeList>;
                }}
            </AutoSizer>
        </div>
    );
}
