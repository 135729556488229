import APSectionMessage from 'ap-components/src/elements/APSectionMessage';
import { DemoPage, DemoSection, DemoItem } from './Common'
import { APPalette } from 'ap-components';

export default function SectionMessagePage() {
    let items = [{
        label: "action 1",
        onClick: () => console.log("hello1")

    },
    {
        label: "action 2",
        onClick: () => console.log("hello2")


    }, {
        label: "action 3",
        onClick: () => console.log("hello3")


    }];

    return (
        <DemoPage label="Section Message">
            <DemoSection label='Section Message' style={{ backgroundColor: APPalette['grey-50'] }}>
                <DemoItem label="Default" >
                    <APSectionMessage
                        title={'Message title'}
                        description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias consequuntur sint voluptate blanditiis aliquid repudiandae mollitia ipsa! Culpa rem dolores sed enim ea, officiis dolorem, vitae distinctio tempore iure odit?'}
                        actions={items}
                        onClose={() => console.log("close")}
                    />
                </DemoItem>
                <DemoItem label="Info" >
                    <APSectionMessage
                        variant='info'
                        title={'Message title'}
                        description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias consequuntur sint voluptate blanditiis aliquid repudiandae mollitia ipsa! Culpa rem dolores sed enim ea, officiis dolorem, vitae distinctio tempore iure odit?'}
                        actions={items}
                    />
                </DemoItem>
                <DemoItem label="Warning" >
                    <APSectionMessage
                        variant='warning'
                        title={'Message title'}
                        description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias consequuntur sint voluptate blanditiis aliquid repudiandae mollitia ipsa! Culpa rem dolores sed enim ea, officiis dolorem, vitae distinctio tempore iure odit?'}
                        actions={items}
                        onClose={() => console.log("closed warning")}
                    />
                </DemoItem>
                <DemoItem label="Success" >
                    <APSectionMessage
                        variant='success'
                        title={'Message title'}
                        description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias consequuntur sint voluptate blanditiis aliquid repudiandae mollitia ipsa! Culpa rem dolores sed enim ea, officiis dolorem, vitae distinctio tempore iure odit?'}
                        actions={items}
                    />
                </DemoItem>
                <DemoItem label="Negative" >
                    <APSectionMessage
                        variant='negative'
                        title={'Message title'}
                        description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias consequuntur sint voluptate blanditiis aliquid repudiandae mollitia ipsa! Culpa rem dolores sed enim ea, officiis dolorem, vitae distinctio tempore iure odit?'}
                        actions={items}
                    />
                </DemoItem>
            </DemoSection>
        </DemoPage>
    )
}
