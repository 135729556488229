import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useState } from "react";
import { APButton, APDebounceText, APVirtualList } from "../..";
import { APColumn, APRow } from "../../layout/APFlex";
import { APExpanded } from "../../layout/Common";
import { HeaderItem, Option } from "./APFilterMenu";

export type FilterOptionMulti = {
    type: 'multi';
    initialValue: string[];
    options: Option[];
    onChange: (selected: string[]) => any;
};
export function APFilterMulti({ header, options, onChange, initialValue }: { initialValue?: string[]; header: string; options: Option[]; onChange: (v: string[]) => any; }) {

    const [selected, setSelected] = useState<string[]>(initialValue ?? []);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        onChange(selected);
    }, [selected]);


    useEffect(() => {
        if (initialValue)
            setSelected(initialValue);
    }, [initialValue?.join(",") ?? ""]);

    var majoritySelected = selected.length > options.length / 2;

    var filteredOptions = options
        .filter(option => {
            if (searchText.length > 0) {
                return option.label.toLowerCase().includes(searchText.toLowerCase()) || option.value.toLowerCase().includes(searchText.toLowerCase());
            }
            return true;
        });

    return (
        <APColumn mainAxisSize='max' crossAxisAlignment='stretch'>
            <APRow style={{ padding: "8px 8px 8px 0" }} gap="8px">
                {
                    options.length > 7 ?
                        <APExpanded>
                            <APDebounceText onChanged={(v) => { setSearchText(v) }} />
                        </APExpanded>
                        :
                        <HeaderItem text={header} />
                }

                <APButton type="secondary" size="small" onClick={() => {
                    if (!majoritySelected) {
                        setSelected([...options.map(a => a.value)]);
                    } else {
                        setSelected([]);
                    }
                }}>{!majoritySelected ? "Select All" : "Remove All"}</APButton>
            </APRow>

            <APExpanded>
                <APVirtualList itemCount={filteredOptions.length} itemHeight={36} >{({ index }) => {
                    var option = filteredOptions[index];
                    return <FormControlLabel
                        style={{ padding: "0 12px" }}
                        key={index}
                        value={option.value}
                        checked={selected.includes(option.value)}
                        onChange={(e, v) => {
                            if (v) {
                                setSelected([...selected, option.value]);
                            } else {
                                setSelected(selected.filter(e => option.value !== e));
                            }
                        }}
                        control={<Checkbox color="primary" size='small' />}
                        label={option.label}
                    />
                }}</APVirtualList>
            </APExpanded>
        </APColumn>
    );
}
