import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { APColumn } from "../../layout";
import { Option } from './APFilterMultiDropdown';

export interface APFilterSingleDropdownProps {
    /**
      * initial selected value for the dropdown
      */
    initialValue?: string;
    /**
     * array of options for the dropdown
     */
    options: Option[];
    /**
     * callback for changing selected value
     */
    onChange: (v: string, selectedWithLabel?: { label: string, value: string }) => any;
    /**
     * type of dropdown
     */
    type: 'single';
}

export function APFilterSingleDropdown({ options, onChange, initialValue }: APFilterSingleDropdownProps) {

    const [selected, setSelected] = useState<string>(initialValue ?? '');

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    useEffect(() => {
        if (initialValue)
            setSelected(initialValue);
    }, [initialValue]);

    const handleRadioClick = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (value === selected) {
            setSelected("");
        } else {
            setSelected(value);
        }
        event.stopPropagation();
        event.preventDefault();
    };

    return (
        <APColumn crossAxisAlignment="stretch" style={{ paddingBottom: "8px" }}>
            <RadioGroup
                value={selected}
                onChange={(e, v) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleRadioClick(e, v)
                }}>
                {
                    options.map((option, index) => (
                        <FormControlLabel
                            style={{ padding: "0 12px" }}
                            key={index}
                            value={option.value}
                            control={<Radio color='primary' size='small' />}
                            label={option.label}
                        />
                    ))
                }
            </RadioGroup>
        </APColumn>
    );
}