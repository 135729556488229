import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch/Switch'
import { useState } from 'react'

export default function APSwitch({ onChanged, initialValue, label }: { onChanged?: (value: boolean) => void, initialValue?: boolean, label?: string }) {
    const [value, setValue] = useState(initialValue ?? false)
    return (
        <FormControlLabel
            label={label}
            disabled={!onChanged}
            control={
                <Switch
                    checked={value}
                    onChange={(event) => {
                        setValue(event.target.checked);
                        if (onChanged) {
                            onChanged(event.target.checked);
                        }
                    }}
                    color="primary"
                />
            }
        />
    )
}
