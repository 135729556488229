import { APButton, showConfirmationDialog, showErrorDialog, launchDialog, handleErrorMessage, APDialogCard, APColumn, APExpanded, Deferred } from "ap-components";
import { DemoPage, DemoSection } from "./Common";
import { DemoTable } from "./Tables";

export default function DialogsPage() {

    return <DemoPage label="Dialogs">

        <DemoSection label='Confirmation Dialog' style={{ width: "400px" }}>
            <APButton onClick={async () => {
                await showConfirmationDialog("Delete Event?", "Are you sure you want to delete the selected event?", "Yes", "No, I'm not");
            }}> Launch Dialog</APButton>
        </DemoSection>


        <DemoSection label='Error Dialog' style={{ width: "400px" }}>
            <APButton onClick={async () => {
                await showErrorDialog("Your changes were saved but we could not connect your account due to technical issue on our end. Please try connecting again. If the issue keeps happening, contact Customer Care", { title: "Unable to connect your account", yesAction: "Try again" });
            }}> Launch Dialog</APButton>
        </DemoSection>

        <DemoSection label="Custom Dialog" style={{ width: "400px" }}>
            <APButton onClick={async () => {
                try {
                    await launchDialog<void>(deferred => <CustomDialog />)
                } catch (error) {
                    handleErrorMessage(error);
                }
            }}>
                Launch Dialog
            </APButton>
        </DemoSection>

    </DemoPage>
}

function CustomDialog() {
    return (
        <APDialogCard
            title="Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui animi error ducimus. Sapiente quasi similique maiores repellendus voluptates esse modi commodi quaerat alias dolorum voluptatem rerum, harum dolores aliquam. Error id illo dignissimos debitis odio? Eaque facilis, dignissimos impedit, asperiores sunt maxime cumque ducimus obcaecati, eos nulla veniam itaque. Et, mollitia! Repellat rem vero impedit eius autem beatae repudiandae perferendis nobis quibusdam magnam accusamus voluptatibus delectus fugiat officiis, recusandae sint assumenda temporibus esse quia deserunt illo. Ullam a consectetur reiciendis animi corporis ratione illo, obcaecati hic architecto assumenda eveniet voluptas natus? Magni pariatur, tempora voluptatibus totam harum unde doloremque non modi nam ea dolores maxime a id dolore voluptatum vel expedita veniam, aliquid consequuntur libero odit iste consectetur? Ipsam maiores provident fugiat nam facere culpa veniam voluptate aperiam neque, placeat beatae ratione aliquam eligendi laudantium. Delectus enim officiis quo eveniet fugit laborum facere maxime a! Nulla obcaecati repellat exercitationem nihil corporis eligendi quis, adipisci eius itaque, quam aliquid, quo aut molestias pariatur. Adipisci aut, id laboriosam inventore, a amet ullam ut impedit repellat praesentium magni cum perferendis velit provident eius? Repellendus ipsam sed debitis magni, placeat nobis quas voluptate reiciendis alias dicta odit odio repudiandae? Illo quia fuga ullam? Tempore!"
            style={{ height: "90vh" }}
            leading={
                <>
                    <APButton size="small" type="secondary" onClick={() => { }}>Leading Button 1</APButton>
                    <APButton size="small" type="secondary" onClick={() => { }}>Leading Button 2</APButton>
                </>
            }
            trailing={
                <>
                    <APButton size="small" type="secondary" onClick={() => { }}>Trailing Button 1</APButton>
                    <APButton size="small" type="secondary" onClick={() => { }}>Trailing Button 2</APButton>
                </>
            }
        >
            <APColumn crossAxisAlignment="stretch" mainAxisSize="max" >
                <APExpanded>
                    <DemoTable />
                </APExpanded>
            </APColumn>
        </APDialogCard>
    )
}