import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { APText } from '../elements';
import { APFormContext } from "./APForm";
import { APFormControl, APFormFieldItem } from './Common';

export function APFormFieldRadio<T>(
    {
        style, label, items, onChanged, initialValue, helperText, validator, control, row, disabled
    }: {
        style?: React.CSSProperties;
        label: string;
        items: Array<{ value: T; label: React.ReactElement | string; }>;
        helperText?: string;
        onChanged?: (value: T | null) => any;

        initialValue?: T;
        validator?: (v: T | null) => string | null | Promise<string | null>;
        control?: APFormControl;

        row?: boolean;
        disabled?: boolean;
    }) {

    let inputRef = useRef<any>(null);

    var foundIndex = items.findIndex((item) => item.value === initialValue);


    var control2: APFormControl | undefined = useContext(APFormContext);

    if (control !== undefined) {
        control2 = control;
    }


    const [value, setValue] = useState<number | ''>(foundIndex === -1 ? "" : foundIndex);

    const [error, setError] = useState<string | null>(null);


    async function checkValues(): Promise<boolean> {
        var errorMessage = null;
        if (validator) {
            if (value !== '')
                errorMessage = await validator(items[value].value);

            else
                errorMessage = await validator(null);
        }
        setError(errorMessage);
        if (errorMessage) {
            inputRef.current?.scrollIntoView?.({ behavior: "smooth", block: "center" });
        }
        return !errorMessage;
    }

    var fieldItem: APFormFieldItem = {
        validate: checkValues,
        reset: () => {
            setValue(foundIndex === -1 ? "" : foundIndex);
        }
    };

    useEffect(() => {
        if (control2 !== undefined)
            control2.fields.add(fieldItem);
        return () => {
            if (control2 !== undefined)
                control2.fields.delete(fieldItem);
        };
    });


    useEffect(() => {

        (async () => {

            if (validator && error !== null) {
                var errorMessage = null;
                if (value !== '')
                    errorMessage = await validator(items[value].value);

                else
                    errorMessage = await validator(null);
                setError(errorMessage);
            }


            if (onChanged) {
                if (value !== '') {
                    onChanged(items[value].value);
                } else {
                    onChanged(null);
                }
            }

        })();

    }, [value]);

    return (
        <FormControl
            margin='normal'
            variant='outlined'
            error={!!error}
            style={style}
            ref={inputRef}
            disabled={disabled}
        >
            <FormLabel
                component="legend"
            // style={{ marginLeft: "8px", marginBottom: "8px" }}
            >
                <APText variant="h4">{label}</APText>
            </FormLabel>
            <RadioGroup
                row={row}
                value={value}
                onChange={(v) => {
                    setValue(parseInt(v.target.value));
                }}
            >
                {items.map((item, index) => {
                    return <FormControlLabel key={index} sx={spanWidthStyle} style={{ marginLeft: 0 }} value={index} control={<Radio size='small' color='primary' />} label={item.label} />;
                })}
            </RadioGroup>
            <FormHelperText>{error || helperText}</FormHelperText>
        </FormControl>
    )

}


const spanWidthStyle = {
    '& .MuiFormControlLabel-label': {
        width: "100%"
    }
};