import Badge from '@mui/material/Badge/Badge'
import React from 'react'
import { APPalette } from '../utils'

export interface APBadgeContainerProps {
    /**
     * node on which badge should appear
     */
    icon: React.ReactNode,
    /**
     * badge notification number
     */
    content: React.ReactNode,
    /**
     * max notification number allowed for display
     */
    max?: number,
    /**
     * @default "standard"
     */
    type?: "dot" | "standard",

    /**
     * @default "default"
     */
    variant?: "default" | "primary" | "secondary" | "important"
    /**
     * shape of the component in which overlap will happen
     * @default "rectangular"
     */
    overlap?: "rectangular" | "circular"
}

/**
 * @author `Abhishek Sinha`
 */
export default function APBadgeContainer({ icon, content, max, type = "standard", variant = "default", overlap = "rectangular" }: APBadgeContainerProps) {

    let _color: "default" | "primary" | "secondary" | "error";
    switch (variant) {
        case "important":
            _color = "error";
            break;
        default:
            _color = variant;
    }
    if (type === "dot") {
        _color = "error";
    }
    return (
        <Badge
            max={max}
            style={{ userSelect: "none", msUserSelect: "none", WebkitUserSelect: "none", MozUserSelect: "none" }}
            badgeContent={content}
            color={_color}
            variant={type}
            overlap={overlap}
        >
            {icon}
        </Badge>
    )
}
