import { APColumn, APPalette } from 'ap-components'
import { DemoPage, DemoSection } from './Common'

export default function PalettePage() {
    return (
        <DemoPage label='Palette'>
            <DemoSection label='Brand'>
                {
                    ["brand-100", 'brand-200', "brand-300", "brand-400", "brand-500"].map((e, index) => {
                        return <ColorCard flip={index < 2 ? true : false} key={e} color={e} />
                    })
                }
            </DemoSection>
            <DemoSection label='Negative'>
                {
                    ["negative-100", 'negative-200', "negative-300", "negative-400", "negative-500"].map((e, index) => {
                        return <ColorCard flip={index < 2 ? true : false} key={e} color={e} />
                    })
                }
            </DemoSection>
            <DemoSection label='Positive'>
                {
                    ["positive-100", 'positive-200', "positive-300", "positive-400", "positive-500"].map((e, index) => {
                        return <ColorCard flip={index < 2 ? true : false} key={e} color={e} />
                    })
                }
            </DemoSection>
            <DemoSection label='Warning'>
                {
                    ["warning-100", 'warning-200', "warning-300", "warning-400", "warning-500"].map((e, index) => {
                        return <ColorCard flip={index < 2 ? true : false} key={e} color={e} />
                    })
                }
            </DemoSection>
            <DemoSection label='Info'>
                {
                    ["info-100", 'info-200', "info-300", "info-400", "info-500"].map((e, index) => {
                        return <ColorCard flip={index < 2 ? true : false} key={e} color={e} />
                    })
                }
            </DemoSection>
            <DemoSection label='Grey'>
                {
                    ["grey-50", "grey-100", 'grey-200', "grey-300", "grey-400", "grey-500", "grey-600", "grey-700", "grey-800", "grey-900"].map((e, index) => {
                        return <ColorCard flip={index < 5 ? true : false} key={e} color={e} />
                    })
                }
            </DemoSection>
            <DemoSection label='White' style={{ backgroundColor: "black" }}>
                {
                    ["white"].map((e, index) => {
                        return <ColorCard flip key={e} color={e} />
                    })
                }
            </DemoSection>
        </DemoPage>
    )
}

function ColorCard({ color, flip = false }: { color: string, flip?: boolean }) {
    let fontColor = flip ? "black" : "white"
    return (
        // @ts-ignore
        <APColumn crossAxisAlignment='end' mainAxisAlignment='center' style={{ height: "50px", backgroundColor: APPalette[color], padding: "0 24px", fontWeight: "bold" }} >
            <span style={{ color: fontColor }}>{color}</span>
        </APColumn>
    )
}