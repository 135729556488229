import FilterListRounded from "@mui/icons-material/FilterListRounded";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import React, { useState } from "react";
import { APButton, APExpanded, APFilterAmount, APImage, APPalette, APScrollView, APText, FilterOptionAmount } from "../..";
import { APColumn, APRow } from "../../layout/APFlex";
import { APFilterDate, FilterOptionDate } from "./APFilterDate";
import { APFilterMulti, FilterOptionMulti } from "./APFilterMulti";
import { APFilterRange, FilterOptionRange } from "./APFilterRange";
import { APFilterSingle, FilterOptionSingle } from "./APFilterSingle";

export type Option = {
    label: string;
    value: string;
}

export type FilterOption = { title: string } & (FilterOptionSingle | FilterOptionMulti | FilterOptionDate | FilterOptionRange | FilterOptionAmount);

export function HeaderItem({ text, style }: { text: string, style?: React.CSSProperties }) {
    return <div style={{
        color: "#888",
        marginLeft: "11px",
        marginRight: "11px",
        fontWeight: 500,
        fontSize: "14px",
        letterSpacing: "0.25px",
        lineHeight: "33px",
        ...style
    }}>
        {text}
    </div>
}

export interface IAPFilterMenu {
    options: FilterOption[],
    label: string,
    icon?: any,
    leftIcon?: any,
    /**
     * @deprecated
     */
    headerWidth?: number,
    style?: React.CSSProperties
}

export function APFilterMenu({ options, label, icon, headerWidth = 157, style, leftIcon }: IAPFilterMenu) {
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const [selected, setSelected] = useState(options[0].title);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    var isSelected = false;

    options.forEach(option => {
        switch (option.type) {
            case 'multi':
                isSelected = isSelected || option.initialValue.length !== 0;
                break;
            case 'single':
                isSelected = isSelected || option.initialValue !== option.options[0].value;
                break;
        }
    })

    return <div style={{ ...style, color: "#666" }}>
        {icon
            ? <IconButton
                size='small'
                // color={isSelected ? "primary" : 'inherit'}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                {icon}
            </IconButton>
            : <APButton
                // color={isSelected ? "primary" : 'inherit'}
                type={"secondary"}
                size={"small"}
                style={{
                    paddingRight: "8px",
                    paddingLeft: "8px"
                }}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                {leftIcon ? leftIcon : <FilterListRounded /* htmlColor="#666" */ fontSize={"small"}></FilterListRounded>}
                <span style={{
                    height: "18px",
                    // color: "#666",
                    fontSize: "13px",
                    letterSpacing: "0.4px",
                    lineHeight: "18px",
                }}>
                    {label}
                </span>
            </APButton>}

        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
            PaperProps={{ style: { minWidth: "240px" } }}
        >
            <APColumn crossAxisAlignment="stretch" mainAxisSize="max" style={{ minWidth: 480, height: "500px" }}>
                <APRow style={{ padding: "16px" }} gap="16px">
                    <APExpanded>
                        <APText variant="h3">{label}</APText>
                    </APExpanded>
                    <APText
                        variant="link-medium"
                        style={{ textDecoration: "underline" }}
                        color={APPalette["grey-600"]}
                        onClick={() => {
                            options.forEach(option => {
                                switch (option.type) {
                                    case "single":
                                        option.onChange(option.options[0].value);
                                        break;
                                    case "multi":
                                        option.onChange([]);
                                        break;
                                    case "range":
                                        option.onChange({ min: option.totalRange.min, max: option.totalRange.max });
                                        break;
                                    case "dateRange":
                                        option.onChange({ from: undefined, to: undefined });
                                        break;
                                    case "amount":
                                        option.onChange({ min: 0, max: 0 });
                                        break;
                                }
                            });

                        }}
                    >
                        Reset
                    </APText>
                    <APImage src="/icons/icon-cross.svg" onClick={handleClose} />
                </APRow>
                <Divider></Divider>
                <APExpanded>
                    <APRow crossAxisAlignment="stretch">
                        <APScrollView style={{ width: 157 }}>
                            <APColumn crossAxisAlignment="stretch" gap="8px" style={{ padding: "16px" }}>
                                {
                                    options.map((option, index) => {
                                        let isTouched = false;
                                        switch (option.type) {
                                            case "single":
                                                isTouched = option.initialValue != option.options[0].value;
                                                break;
                                            case "multi":
                                                isTouched = option.initialValue.length != 0;
                                                break;
                                            case "range":
                                                isTouched = (option.initialValue.min != option.totalRange.min || option.initialValue.max != option.totalRange.max);
                                                break;
                                            case "dateRange":
                                                isTouched = (option.initialValue.from != null) || (option.initialValue.to != null);
                                                break;
                                            case "amount":
                                                isTouched = (option.initialValue.min != null) || (option.initialValue.max != null);
                                                break;
                                        }

                                        return (
                                            <APRow key={index} onClick={() => setSelected(option.title)} mainAxisSize="max" gap="8px"
                                                style={{
                                                    padding: "8px",
                                                    borderRadius: "8px",
                                                    backgroundColor: selected == option.title ? APPalette["brand-100"] : undefined,
                                                    color: selected == option.title ? undefined : APPalette["brand-300"]
                                                }}
                                            >
                                                <APExpanded>
                                                    <APText variant="paragraph-small">{option.title}</APText>
                                                </APExpanded>
                                                {
                                                    isTouched &&
                                                    <div style={{ borderRadius: "8px", height: "6px", width: "6px", backgroundColor: APPalette["negative-300"] }} />
                                                }
                                            </APRow>
                                        )
                                    })
                                }
                            </APColumn>
                        </APScrollView>
                        <Divider orientation="vertical" />
                        <APExpanded>
                            <APScrollView style={{ padding: "8px" }}>
                                {options
                                    .filter(option => option.title == selected)
                                    .map((option, index) => {
                                        switch (option.type) {
                                            case 'multi':
                                                return <APFilterMulti key={index} initialValue={option.initialValue} header={option.title} options={option.options} onChange={v => option.onChange(v)} />;
                                            case 'single':
                                                return <APFilterSingle key={index} initialValue={option.initialValue} header={option.title} options={option.options} onChange={v => option.onChange(v)} />;
                                            case 'dateRange':
                                                return <APFilterDate key={index} initialValue={option.initialValue} header={option.title} onChange={v => option.onChange(v)} clearable={option.clearable} />;
                                            case 'range':
                                                return <APFilterRange key={index} initialValue={option.initialValue} totalRange={option.totalRange} header={option.title} onChange={v => option.onChange(v)} />;
                                            case 'amount':
                                                return <APFilterAmount key={index} initialValue={option.initialValue} header={option.title} onChange={v => option.onChange(v)} />;
                                        }
                                    })}
                            </APScrollView>

                        </APExpanded>

                    </APRow>
                </APExpanded>
                <Divider></Divider>
                <APRow mainAxisAlignment="end" style={{ padding: "16px" }}>
                    <APButton size="small" onClick={handleClose}>Apply</APButton>
                </APRow>
            </APColumn>
        </Popover>
    </div>
}