// DO NOT MODIFY BY HAND!!
// Go through Readme.md

import React from 'react';

import { ReactComponent as CorporateBond } from './icon-CorporateBond.svg';
import { ReactComponent as Docs } from './icon-Docs.svg';
import { ReactComponent as Home } from './icon-Home.svg';
import { ReactComponent as Money } from './icon-Money.svg';
import { ReactComponent as Money2 } from './icon-Money2.svg';
import { ReactComponent as Nominee } from './icon-Nominee.svg';
import { ReactComponent as OnlineEmpanelment } from './icon-OnlineEmpanelment.svg';
import { ReactComponent as Protection } from './icon-Protection.svg';
import { ReactComponent as Review } from './icon-Review.svg';
import { ReactComponent as Watchlist } from './icon-Watchlist.svg';
import { ReactComponent as AddClient } from './icon-addClient.svg';
import { ReactComponent as Analysis } from './icon-analysis.svg';
import { ReactComponent as Balance } from './icon-balance.svg';
import { ReactComponent as Bank } from './icon-bank.svg';
import { ReactComponent as Bank2 } from './icon-bank2.svg';
import { ReactComponent as Calculator } from './icon-calculator.svg';
import { ReactComponent as Calendar } from './icon-calendar.svg';
import { ReactComponent as Car } from './icon-car.svg';
import { ReactComponent as Cash } from './icon-cash.svg';
import { ReactComponent as Chair } from './icon-chair.svg';
import { ReactComponent as Chart } from './icon-chart.svg';
import { ReactComponent as Compare } from './icon-compare.svg';
import { ReactComponent as Compare2 } from './icon-compare2.svg';
import { ReactComponent as Conservative } from './icon-conservative.svg';
import { ReactComponent as Cross } from './icon-cross.svg';
import { ReactComponent as Dividend } from './icon-dividend.svg';
import { ReactComponent as ExpertSupport } from './icon-expertSupport.svg';
import { ReactComponent as Explore } from './icon-explore.svg';
import { ReactComponent as Faq } from './icon-faq.svg';
import { ReactComponent as Faq2 } from './icon-faq2.svg';
import { ReactComponent as Filter } from './icon-filter.svg';
import { ReactComponent as Flexible } from './icon-flexible.svg';
import { ReactComponent as Focus } from './icon-focus.svg';
import { ReactComponent as Friendly } from './icon-friendly.svg';
import { ReactComponent as Goal } from './icon-goal.svg';
import { ReactComponent as Gold } from './icon-gold.svg';
import { ReactComponent as GraduateBoy } from './icon-graduate-boy.svg';
import { ReactComponent as GraduateGirl } from './icon-graduate-girl.svg';
import { ReactComponent as Health } from './icon-health.svg';
import { ReactComponent as HourGlass } from './icon-hourGlass.svg';
import { ReactComponent as Identity } from './icon-identity.svg';
import { ReactComponent as Income } from './icon-income.svg';
import { ReactComponent as Inflation } from './icon-inflation.svg';
import { ReactComponent as Installment } from './icon-installment.svg';
import { ReactComponent as Insurance } from './icon-insurance.svg';
import { ReactComponent as International } from './icon-international.svg';
import { ReactComponent as Learning } from './icon-learning.svg';
import { ReactComponent as Like } from './icon-like.svg';
import { ReactComponent as Loan } from './icon-loan.svg';
import { ReactComponent as Loan2 } from './icon-loan2.svg';
import { ReactComponent as Logout } from './icon-logout.svg';
import { ReactComponent as Lumpsum } from './icon-lumpsum.svg';
import { ReactComponent as Marketing } from './icon-marketing.svg';
import { ReactComponent as Megaphone } from './icon-megaphone.svg';
import { ReactComponent as MutualFunds } from './icon-mutualFunds.svg';
import { ReactComponent as New } from './icon-new.svg';
import { ReactComponent as News } from './icon-news.svg';
import { ReactComponent as Offer } from './icon-offer.svg';
import { ReactComponent as Orders } from './icon-orders.svg';
import { ReactComponent as Pdf } from './icon-pdf.svg';
import { ReactComponent as Performance } from './icon-performance.svg';
import { ReactComponent as Portfolio } from './icon-portfolio.svg';
import { ReactComponent as Poster } from './icon-poster.svg';
import { ReactComponent as Ppt } from './icon-ppt.svg';
import { ReactComponent as Refer } from './icon-refer.svg';
import { ReactComponent as Report } from './icon-report.svg';
import { ReactComponent as Research } from './icon-research.svg';
import { ReactComponent as Returns } from './icon-returns.svg';
import { ReactComponent as Revenue } from './icon-revenue.svg';
import { ReactComponent as Reward2 } from './icon-reward-2.svg';
import { ReactComponent as Reward3 } from './icon-reward-3.svg';
import { ReactComponent as Reward } from './icon-reward.svg';
import { ReactComponent as Savings } from './icon-savings.svg';
import { ReactComponent as Settings } from './icon-settings.svg';
import { ReactComponent as Share } from './icon-share.svg';
import { ReactComponent as ShortTerm } from './icon-shortTerm.svg';
import { ReactComponent as Tax } from './icon-tax.svg';
import { ReactComponent as Tax2 } from './icon-tax2.svg';
import { ReactComponent as TaxDoc2 } from './icon-taxDoc2.svg';
import { ReactComponent as Tech } from './icon-tech.svg';
import { ReactComponent as TechSupport } from './icon-techSupport.svg';
import { ReactComponent as Term } from './icon-term.svg';
import { ReactComponent as Tick } from './icon-tick.svg';
import { ReactComponent as Time2 } from './icon-time2.svg';
import { ReactComponent as Top } from './icon-top.svg';
import { ReactComponent as Track } from './icon-track.svg';
import { ReactComponent as Training } from './icon-training.svg';
import { ReactComponent as Transaction } from './icon-transaction.svg';
import { ReactComponent as Translate } from './icon-translate.svg';
import { ReactComponent as Unique } from './icon-unique.svg';
import { ReactComponent as User } from './icon-user.svg';
import { ReactComponent as Vault } from './icon-vault.svg';
import { ReactComponent as Video } from './icon-video.svg';
import { ReactComponent as Warning } from './icon-warning.svg';

export type ColouredSVGIcon = "corporateBond" | "docs" | "home" | "money" | "money2" | "nominee" | "onlineEmpanelment" | "protection" | "review" | "watchlist" | "addClient" | "analysis" | "balance" | "bank" | "bank2" | "calculator" | "calendar" | "car" | "cash" | "chair" | "chart" | "compare" | "compare2" | "conservative" | "cross" | "dividend" | "expertSupport" | "explore" | "faq" | "faq2" | "filter" | "flexible" | "focus" | "friendly" | "goal" | "gold" | "graduateBoy" | "graduateGirl" | "health" | "hourGlass" | "identity" | "income" | "inflation" | "installment" | "insurance" | "international" | "learning" | "like" | "loan" | "loan2" | "logout" | "lumpsum" | "marketing" | "megaphone" | "mutualFunds" | "new" | "news" | "offer" | "orders" | "pdf" | "performance" | "portfolio" | "poster" | "ppt" | "refer" | "report" | "research" | "returns" | "revenue" | "reward2" | "reward3" | "reward" | "savings" | "settings" | "share" | "shortTerm" | "tax" | "tax2" | "taxDoc2" | "tech" | "techSupport" | "term" | "tick" | "time2" | "top" | "track" | "training" | "transaction" | "translate" | "unique" | "user" | "vault" | "video" | "warning";


export const ColouredSVGIconNames = ["corporateBond", "docs", "home", "money", "money2", "nominee", "onlineEmpanelment", "protection", "review", "watchlist", "addClient", "analysis", "balance", "bank", "bank2", "calculator", "calendar", "car", "cash", "chair", "chart", "compare", "compare2", "conservative", "cross", "dividend", "expertSupport", "explore", "faq", "faq2", "filter", "flexible", "focus", "friendly", "goal", "gold", "graduateBoy", "graduateGirl", "health", "hourGlass", "identity", "income", "inflation", "installment", "insurance", "international", "learning", "like", "loan", "loan2", "logout", "lumpsum", "marketing", "megaphone", "mutualFunds", "new", "news", "offer", "orders", "pdf", "performance", "portfolio", "poster", "ppt", "refer", "report", "research", "returns", "revenue", "reward2", "reward3", "reward", "savings", "settings", "share", "shortTerm", "tax", "tax2", "taxDoc2", "tech", "techSupport", "term", "tick", "time2", "top", "track", "training", "transaction", "translate", "unique", "user", "vault", "video", "warning"];

function getIcon(icon: ColouredSVGIcon) {
    switch (icon) {
   case "corporateBond": return <CorporateBond />;
   case "docs": return <Docs />;
   case "home": return <Home />;
   case "money": return <Money />;
   case "money2": return <Money2 />;
   case "nominee": return <Nominee />;
   case "onlineEmpanelment": return <OnlineEmpanelment />;
   case "protection": return <Protection />;
   case "review": return <Review />;
   case "watchlist": return <Watchlist />;
   case "addClient": return <AddClient />;
   case "analysis": return <Analysis />;
   case "balance": return <Balance />;
   case "bank": return <Bank />;
   case "bank2": return <Bank2 />;
   case "calculator": return <Calculator />;
   case "calendar": return <Calendar />;
   case "car": return <Car />;
   case "cash": return <Cash />;
   case "chair": return <Chair />;
   case "chart": return <Chart />;
   case "compare": return <Compare />;
   case "compare2": return <Compare2 />;
   case "conservative": return <Conservative />;
   case "cross": return <Cross />;
   case "dividend": return <Dividend />;
   case "expertSupport": return <ExpertSupport />;
   case "explore": return <Explore />;
   case "faq": return <Faq />;
   case "faq2": return <Faq2 />;
   case "filter": return <Filter />;
   case "flexible": return <Flexible />;
   case "focus": return <Focus />;
   case "friendly": return <Friendly />;
   case "goal": return <Goal />;
   case "gold": return <Gold />;
   case "graduateBoy": return <GraduateBoy />;
   case "graduateGirl": return <GraduateGirl />;
   case "health": return <Health />;
   case "hourGlass": return <HourGlass />;
   case "identity": return <Identity />;
   case "income": return <Income />;
   case "inflation": return <Inflation />;
   case "installment": return <Installment />;
   case "insurance": return <Insurance />;
   case "international": return <International />;
   case "learning": return <Learning />;
   case "like": return <Like />;
   case "loan": return <Loan />;
   case "loan2": return <Loan2 />;
   case "logout": return <Logout />;
   case "lumpsum": return <Lumpsum />;
   case "marketing": return <Marketing />;
   case "megaphone": return <Megaphone />;
   case "mutualFunds": return <MutualFunds />;
   case "new": return <New />;
   case "news": return <News />;
   case "offer": return <Offer />;
   case "orders": return <Orders />;
   case "pdf": return <Pdf />;
   case "performance": return <Performance />;
   case "portfolio": return <Portfolio />;
   case "poster": return <Poster />;
   case "ppt": return <Ppt />;
   case "refer": return <Refer />;
   case "report": return <Report />;
   case "research": return <Research />;
   case "returns": return <Returns />;
   case "revenue": return <Revenue />;
   case "reward2": return <Reward2 />;
   case "reward3": return <Reward3 />;
   case "reward": return <Reward />;
   case "savings": return <Savings />;
   case "settings": return <Settings />;
   case "share": return <Share />;
   case "shortTerm": return <ShortTerm />;
   case "tax": return <Tax />;
   case "tax2": return <Tax2 />;
   case "taxDoc2": return <TaxDoc2 />;
   case "tech": return <Tech />;
   case "techSupport": return <TechSupport />;
   case "term": return <Term />;
   case "tick": return <Tick />;
   case "time2": return <Time2 />;
   case "top": return <Top />;
   case "track": return <Track />;
   case "training": return <Training />;
   case "transaction": return <Transaction />;
   case "translate": return <Translate />;
   case "unique": return <Unique />;
   case "user": return <User />;
   case "vault": return <Vault />;
   case "video": return <Video />;
   case "warning": return <Warning />;
default:
            return <div></div>
    }
}

interface APColouredIconProps {
    icon: ColouredSVGIcon,
    /**
     * @default 24
     */
    size?: number,
}

export function APColouredIcon({ icon, size = 24 }: APColouredIconProps) {
    return React.cloneElement(getIcon(icon), { width: size, height: size })
}
