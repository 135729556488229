import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { APFormContext } from "./APForm";
import { APFormControl, APFormFieldItem } from './Common';


export function APFormFieldCheckbox({
    style, label, initialValue, readOnly = false, helperText, disabled, onChanged, validator, control
}: {
    style?: React.CSSProperties;
    label: string;
    helperText?: string;
    initialValue?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    onChanged?: (v: boolean) => any;
    validator?: (v: boolean) => string | null | Promise<string | null>;
    control?: APFormControl;
}) {

    let inputRef = useRef<any>(null);

    var control2: APFormControl | undefined = useContext(APFormContext);

    if (control !== undefined) {
        control2 = control;
    }

    const [value, setValue] = useState(initialValue || false);

    const [error, setError] = useState<string | null>(null);

    async function checkValues(): Promise<boolean> {
        var errorMessage = null;
        if (validator) {
            errorMessage = await validator(value);
        }
        setError(errorMessage);
        if (errorMessage) {
            inputRef.current?.scrollIntoView?.({ behavior: "smooth", block: "center" });
        }
        return !errorMessage;
    }

    var fieldItem: APFormFieldItem = {
        validate: checkValues,
        reset: () => {
            setValue(initialValue || false);
        }
    };

    useEffect(() => {
        if (control2 !== undefined)
            control2.fields.add(fieldItem);
        return () => {
            if (control2 !== undefined)
                control2.fields.delete(fieldItem);
        };
    });

    useEffect(() => {
        (async () => {
            if (validator && error !== null) {
                setError(await validator(value));
            }
            if (onChanged)
                onChanged(value);
        })();

    }, [value]);


    return <FormControl
        variant='outlined'
        size='small'
        ref={inputRef}
        error={!!error} style={style}>
        <FormControlLabel
            style={{ marginLeft: "6px", marginTop: "4px", marginBottom: "4px" }}
            control={<Checkbox
                style={{ padding: '2px', marginRight: "8px" }}
                // indeterminate={true}
                checked={value}
                disabled={disabled}
                onChange={() => setValue(!value)}
                color="primary" />}
            label={label} />
        {(error || helperText) && <FormHelperText>{error || helperText}</FormHelperText>}
    </FormControl>;

}
