import { TextVariants, APText, TypographyVariants } from "ap-components";
import { DemoItem, DemoPage, DemoSection } from "./Common";

export default function Buttons() {

    var sampleText = "The quick brown fox jumps over the lazy dog";

    return <DemoPage label="Typography">
        <DemoSection label="Headers" >
            {([
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
            ] as TypographyVariants[])
                .map((style, index) => {
                    return <DemoItem key={index} label={style}>
                        <APText variant={style}>{sampleText}</APText>
                    </DemoItem>
                })}
        </DemoSection>
        <DemoSection label="Paragraph" >
            {([
                'paragraph-small',
                'paragraph-medium',
                'paragraph-large',
                'paragraph-xsmall',
            ] as TypographyVariants[])
                .map((style, index) => {
                    return <DemoItem key={index} label={style}>
                        <APText variant={style}>{sampleText}</APText>
                    </DemoItem>
                })}
        </DemoSection>
        <DemoSection label="Button" >
            {([
                'button-small',
                'button-medium',
                'button-large',
            ] as TypographyVariants[])
                .map((style, index) => {
                    return <DemoItem key={index} label={style}>
                        <APText variant={style}>{sampleText}</APText>
                    </DemoItem>
                })}
        </DemoSection>
        <DemoSection label="Link" >
            {([
                'link-small',
                'link-medium',
                'link-large',
            ] as TypographyVariants[])
                .map((style, index) => {
                    return <DemoItem key={index} label={style}>
                        <APText variant={style}>{sampleText}</APText>
                    </DemoItem>
                })}
        </DemoSection>
        <DemoSection label="Basic" deprecated>
            {([
                'normal',
                'maintext',
                'subtext',
            ] as TextVariants[]).map((style, index) => {
                return <DemoItem key={index} label={style}>
                    <APText variant={style}>{sampleText}</APText>
                </DemoItem>
            })}
        </DemoSection>
        <DemoSection label="Card Texts" deprecated>
            {([
                'dialogTitle',
                'cardTitleLarge',
                'cardTitle',
                'cardSubtitle',
            ] as TextVariants[])
                .map((style, index) => {
                    return <DemoItem key={index} label={style}>
                        <APText variant={style}>{sampleText}</APText>
                    </DemoItem>
                })}
        </DemoSection>
    </DemoPage>
}