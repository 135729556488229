import Tooltip from '@mui/material/Tooltip'
import React from 'react'

export interface APTooltipProps {
    children: React.ReactElement,
    /**
     * tooltip title
     */
    message: React.ReactNode,
    /**
     * The number of milliseconds to wait before showing the tooltip.
     * @default 100
     */
    enterDelay?: number,
    /**
     * placement of the tooltip relative to the component
     * @default bottom
     */
    placement?:
    'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top',
    style?: React.CSSProperties
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

/**
 * @author `Abhishek Sinha`
 * @param APTooltipProps
 */
export default function APTooltip({ children, style, message, enterDelay, placement = "bottom", onClick }: APTooltipProps) {
    return (
        <Tooltip
            title={message}
            style={style}
            placement={placement}
            enterDelay={enterDelay}
            onClick={onClick}
        >
            {children}
        </Tooltip>
    )
}
