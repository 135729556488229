import { APPalette } from "../utils";
import APTooltip from "./APTooltip";

export function APInfo({ children, tip }: { children: string, tip: string }) {
    return (
        <APTooltip message={tip} placement="top">
            <span style={{
                textDecoration: "underline",
                textDecorationStyle: "dashed",
                textDecorationColor: APPalette["grey-400"],
                textUnderlineOffset: "5px",
                width: "fit-content",
            }}>{children}</span>
        </APTooltip>
    )
}