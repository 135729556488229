import AddAPhoto from '@mui/icons-material/AddAPhoto';
import IconButton from '@mui/material/IconButton';
import { APRow, APBadge, APPalette, APSizedBox } from 'ap-components';
import APBadgeContainer from 'ap-components/src/elements/APBadgeContainer';
import { DemoItem, DemoPage, DemoSection } from './Common';

export default function Badge() {
    return (
        <DemoPage label="Badge">
            <DemoSection label='Badge' style={{ backgroundColor: APPalette['grey-100'] }}>
                <DemoItem label="default">
                    <APBadge variant='default' content={100000} />
                    <APSizedBox height='8px' />
                    <APBadge variant='default' max={100} content={100000} />
                </DemoItem>
                <DemoItem label="important">
                    <APBadge variant='important' content={100000} />
                    <APSizedBox height='8px' />
                    <APBadge variant='important' max={100} content={100000} />
                </DemoItem>
                <DemoItem label="primary">
                    <APBadge variant='primary' content={100000} />
                    <APSizedBox height='8px' />
                    <APBadge variant='primary' max={100} content={100000} />
                </DemoItem>
                <DemoItem label="secondary">
                    <APBadge variant='secondary' content={100000} />
                    <APSizedBox height='8px' />
                    <APBadge variant='secondary' max={100} content={100000} />
                </DemoItem>
            </DemoSection>
            <DemoSection label='Badge Container'>
                <DemoItem label="">
                    <APRow mainAxisAlignment="start" gap={"26px"}>
                        <APBadgeContainer
                            icon={
                                <div
                                    style={{ backgroundColor: "aliceblue", padding: "12px", maxHeight: "48px", maxWidth: "48px" }}
                                    onClick={() => console.log("just a simple click event")}>
                                    <AddAPhoto />
                                </div>
                            }
                            content={10000000}
                        />
                        <APBadgeContainer
                            icon={
                                <div
                                    style={{ backgroundColor: "aliceblue", padding: "12px", maxHeight: "48px", maxWidth: "48px" }}
                                    onClick={() => console.log("just a simple click event")}>
                                    <AddAPhoto />
                                </div>
                            }
                            content={25}
                            variant='important'
                        />
                        <APBadgeContainer
                            icon={
                                <div
                                    style={{ backgroundColor: "aliceblue", padding: "12px", maxHeight: "48px", maxWidth: "48px" }}
                                    onClick={() => console.log("just a simple click event")}>
                                    <AddAPhoto />
                                </div>
                            }
                            max={9999}
                            content={2500}
                            variant='important'
                        />
                    </APRow>
                </DemoItem>
                <DemoItem label='overlap="circular"'>
                    <APRow mainAxisAlignment="start" gap={"36px"}>
                        <APBadgeContainer
                            overlap='circular'
                            icon={
                                <IconButton
                                    style={{ backgroundColor: "aliceblue" }}
                                    onClick={() => console.log("just a simple click event")}
                                    size="large">
                                    <AddAPhoto />
                                </IconButton>
                            }
                            content={10000000}
                        />
                        <APBadgeContainer
                            overlap='circular'
                            icon={
                                <IconButton
                                    style={{ backgroundColor: "aliceblue" }}
                                    onClick={() => console.log("just a simple click event")}
                                    size="large">
                                    <AddAPhoto />
                                </IconButton>
                            }
                            content={1}
                        />
                    </APRow>
                </DemoItem>
                <DemoItem label='max={1000}' >
                    <APRow mainAxisAlignment="start" gap={"36px"}>
                        <APBadgeContainer
                            overlap='circular'
                            icon={
                                <IconButton
                                    style={{ backgroundColor: "aliceblue" }}
                                    onClick={() => console.log("just a simple click event")}
                                    size="large">
                                    <AddAPhoto />
                                </IconButton>
                            }
                            content={10000}
                            max={1000}
                        />
                        <APBadgeContainer
                            icon={
                                <div
                                    style={{ backgroundColor: "aliceblue", padding: "12px", maxHeight: "48px", maxWidth: "48px" }}
                                    onClick={() => console.log("just a simple click event")}>
                                    <AddAPhoto />
                                </div>
                            }
                            content={10000000}
                            max={1000}
                        />
                    </APRow>
                </DemoItem>
                <DemoItem label='variant="dot"' >
                    <APRow mainAxisAlignment="start" gap={"36px"}>
                        <APBadgeContainer
                            overlap='circular'
                            icon={
                                <IconButton
                                    style={{ backgroundColor: "aliceblue" }}
                                    onClick={() => console.log("just a simple click event")}
                                    size="large">
                                    <AddAPhoto />
                                </IconButton>
                            }
                            content={10000}
                            max={1000}
                            type="dot"
                        />
                        <APBadgeContainer
                            icon={
                                <div
                                    style={{ backgroundColor: "aliceblue", padding: "12px", maxHeight: "48px", maxWidth: "48px" }}
                                    onClick={() => console.log("just a simple click event")}>
                                    <AddAPhoto />
                                </div>
                            }
                            content={10000000}
                            max={1000}
                            type="dot"
                        />
                        <APBadgeContainer
                            icon={
                                <div
                                    style={{ backgroundColor: "aliceblue", padding: "12px", maxHeight: "48px", maxWidth: "48px" }}
                                    onClick={() => console.log("just a simple click event")}>
                                    <AddAPhoto />
                                </div>
                            }
                            content={10000000}
                            max={1000}
                            type="dot"
                        />
                    </APRow>
                </DemoItem>
                <DemoItem label='variant' >
                    <APRow mainAxisAlignment="start" gap={"36px"}>
                        <APBadgeContainer
                            overlap='circular'
                            icon={
                                <IconButton
                                    style={{ backgroundColor: "aliceblue" }}
                                    onClick={() => console.log("just a simple click event")}
                                    size="large">
                                    <AddAPhoto />
                                </IconButton>
                            }
                            content={10000}
                            max={1000}
                        />
                        <APBadgeContainer
                            icon={
                                <div
                                    style={{ backgroundColor: "aliceblue", padding: "12px", maxHeight: "48px", maxWidth: "48px" }}
                                    onClick={() => console.log("just a simple click event")}>
                                    <AddAPhoto />
                                </div>
                            }
                            content={10000000}
                            max={1000}
                            variant='primary'
                        />
                        <APBadgeContainer
                            overlap='circular'
                            icon={
                                <IconButton
                                    style={{ backgroundColor: "aliceblue" }}
                                    onClick={() => console.log("just a simple click event")}
                                    size="large">
                                    <AddAPhoto />
                                </IconButton>
                            }
                            content={10000}
                            max={1000}
                            variant='secondary'
                        />
                        <APBadgeContainer
                            icon={
                                <div
                                    style={{ backgroundColor: "aliceblue", padding: "12px", maxHeight: "48px", maxWidth: "48px" }}
                                    onClick={() => console.log("just a simple click event")}>
                                    <AddAPhoto />
                                </div>
                            }
                            content={10000000}
                            max={1000}
                            variant='important'
                        />
                    </APRow>
                </DemoItem>
            </DemoSection>
        </DemoPage>
    );
}
