import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { APColumn } from "../layout";
import { APText } from "./APText";
import { APIcon } from "../icons/APIcon";

export function APAccordion({ title, style, children }: { title: string, style?: React.CSSProperties, children: JSX.Element }) {
    return (
        <APColumn crossAxisAlignment="stretch">
            <Accordion elevation={0} style={style}>
                <AccordionSummary
                    expandIcon={<APIcon icon="down" size={16} color="black" />}
                >
                    <APText variant="h3">{title}</APText>
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
        </APColumn>
    )
}
