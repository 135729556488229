import { APColumn, APExpanded, APFormFieldText, APJourneyWeb, APPadding, APRow, APText, NArray, REGEX, sleep } from 'ap-components'
import { DemoPage, DemoSection } from './Common'
import { useState } from 'react';

export default function SectionMessagePage() {

    let [formData] = useState<any>({});

    return (
        <DemoPage label="Stepper Message">
            <APPadding noClone>
                <APJourneyWeb
                    style={{ width: "100%", height: "500px" }}
                    mode='linear'
                    title="Mutual Fund Activation"
                    description='Activate account to invest in Mutual Funds'
                    steps={[
                        {
                            title: "PAN Details",
                            description: "Investments will be linked to your PAN number",
                            form: <APColumn crossAxisAlignment='start'>
                                {NArray(10).map((a, index) => {
                                    return <APText key={index}>HELLO WORLD</APText>
                                })}
                                <APRow>
                                    <APFormFieldText label={"PAN Number"}
                                        validator={(v) => {
                                            if (!REGEX.PAN.test(v)) {
                                                return "Invalid PAN Number"
                                            }
                                        }}
                                        onChanged={(v) => {
                                            formData.pan = v;
                                        }}
                                    />
                                </APRow>
                                {NArray(10).map((a, index) => {
                                    return <APText key={index + "aa"}>HELLO WORLD</APText>
                                })}
                                <APRow>
                                    <APFormFieldText label={"PAN Number 2"}
                                        validator={(v) => {
                                            if (!REGEX.PAN.test(v)) {
                                                return "Invalid PAN Number"
                                            }
                                        }}
                                        onChanged={(v) => {
                                            formData.pan = v;
                                        }}
                                    />
                                </APRow>
                                {NArray(10).map((a, index) => {
                                    return <APText key={index + "a"}>HELLO WORLD</APText>
                                })}

                            </APColumn>,
                            onSubmit: async () => {
                                await sleep(1000);
                                if (formData.pan === "AAAAA1111A") {
                                    throw new Error("PAN Number already exists");
                                }
                                console.log("Form 1 Submitted")
                            }
                        },
                        {
                            title: "Personal Details",
                            description: "Your personal details will be used for KYC and Folio Creation",
                            steps: [
                                {
                                    title: "Father Details",
                                    description: "Details of your father",
                                    form: <APColumn crossAxisAlignment='start'>
                                        <APRow>
                                            <APFormFieldText label={"Father PAN Number"} validator={(v) => {
                                                if (!REGEX.PAN.test(v)) {
                                                    return "Invalid PAN Number"
                                                }
                                            }}
                                                onChanged={(v) => {
                                                    formData.pan = v;
                                                }}
                                            />
                                        </APRow>

                                    </APColumn>,
                                    onSubmit: async () => {
                                        console.log("Form 2.1 Submitted")
                                    }
                                },
                                {
                                    title: "Mother Details",
                                    description: "Details of your mother",
                                    form: <APColumn crossAxisAlignment='start'>
                                        <APRow>
                                            <APFormFieldText label={"Mother PAN"} validator={(v) => {
                                                if (!REGEX.PAN.test(v)) {
                                                    return "Invalid PAN Number"
                                                }
                                            }}
                                                onChanged={(v) => {
                                                    formData.pan = v;
                                                }}
                                            />
                                        </APRow>

                                    </APColumn>,
                                    onSubmit: async () => {
                                        console.log("Form 2.2 Submitted")
                                    }
                                },
                            ]
                        }
                    ]}

                />
            </APPadding>
        </DemoPage>
    )
}
