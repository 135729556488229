import Slider from "@mui/material/Slider";
import { useEffect, useState } from "react";
import { APColumn, APRow } from "../../layout/APFlex";
import { APPadding } from "../../layout/Common";
import { HeaderItem } from "./APFilterMenu";


export type APNumberRange = { min: number; max: number; };
export type FilterOptionRange = {
    type: 'range';
    totalRange: APNumberRange;
    initialValue: APNumberRange;
    onChange: (range: APNumberRange) => any;
};
export function APFilterRange({ header, onChange, initialValue, totalRange }: { initialValue?: APNumberRange; totalRange: APNumberRange; header: string; onChange: (v: APNumberRange) => any; }) {

    const [value, setValue] = useState(initialValue ? [initialValue.min, initialValue.max] : [totalRange.min, totalRange.max]);

    useEffect(() => {
        onChange({ min: value[0], max: value[1] });
    }, [value[0], value[1]]);


    useEffect(() => {
        if (initialValue)
            setValue([initialValue.min, initialValue.max]);
    }, [initialValue?.min, initialValue?.max]);

    return <APColumn mainAxisSize='min' crossAxisAlignment='stretch'>
        <HeaderItem text={header} />
        <APPadding padding={"4px 24px 12px"}>
            <APRow crossAxisAlignment={'center'} mainAxisSize='max' gap={"6px"}>
                <Slider
                    color='primary'
                    value={value}
                    min={totalRange.min}
                    max={totalRange.max}
                    marks={[
                        {
                            value: totalRange.min,
                            label: totalRange.min,
                        }, {
                            value: totalRange.max,
                            label: totalRange.max
                        }
                    ]}
                    onChange={(e, v) => {
                        if (Array.isArray(v))
                            setValue(v);
                    }}
                    valueLabelDisplay="auto" />
            </APRow>
        </APPadding>
    </APColumn>;
}
