import { Popover } from '@mui/material';
import { useEffect, useState } from 'react'
import { APScrollView } from '../../layout';
import { APText } from '../../elements/APText';
import { APButton } from '../../elements';
import { APFilterMultiDropdown, APFilterMultiDropdownProps } from './APFilterMultiDropdown';
import { APFilterSingleDropdown, APFilterSingleDropdownProps } from './APFilterSingleDropdown';
import { APPalette } from '../../utils';
import { DownIcon, UpIcon } from '../../icons';

export type APDropdownFilterProps = {
    /**
     *  label of the filter which should appear
     */
    label: string
}
    & (
        /**
        * props for the multi dropdown
        */
        APFilterMultiDropdownProps
        /**
        * props for the single dropdown
        */
        | APFilterSingleDropdownProps
    )

export function APDropdownFilter({ initialValue, onChange, options, type, label }: APDropdownFilterProps) {
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [isOpen, setOpen] = useState(false);
    const [multiSelectedValue, setMultiSelectedValue] = useState<string[]>((type === "multi" && initialValue) ?
        initialValue.filter(e => options.map(e => e.value).includes(e))
        :
        []
    )
    const [singleSelectedValue, setSingleSelectedValue] = useState<string | undefined>((type === "single" && initialValue) ?
        (options.map(e => e.value).includes(initialValue)) ? initialValue : undefined : undefined)

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    var isSelected = false;

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    useEffect(() => {
        if (type === 'single') {
            onChange(singleSelectedValue ?? "")
        }
        else {
            onChange(multiSelectedValue)
        }
    }, [])

    useEffect(() => {
        manageDropdown()
    }, [open])

    const manageDropdown = () => {
        if (id == 'simple-popover') {
            setOpen(true)
        }
    }

    switch (type) {
        case 'multi':
            isSelected = isSelected || initialValue?.length !== 0;
            break;
        case 'single':
            isSelected = isSelected || initialValue !== options[0].value;
            break;
    }

    let selectedCount = 0
    if (type === 'multi') {
        selectedCount = multiSelectedValue.length
    }

    const customStyle = (selectedCount || singleSelectedValue) ?
        {
            backgroundColor: APPalette["brand-100"],
            border: `1px solid ${APPalette['brand-200']}`,
            paddingRight: "10px",
            paddingLeft: "12px",
            borderRadius: '20px',
            height: '40px'
        }
        :
        {
            paddingRight: "10px",
            paddingLeft: "12px",
            borderRadius: '20px',
            height: '40px',
            '&:hover': {
                backgroundColor: APPalette['grey-200'],
            },
        };


    return <>
        <div style={{ color: "#666" }}>
            <APButton
                color={isSelected ? "primary" : 'inherit'}
                type={"secondary"}
                size={"small"}
                style={{ ...customStyle }}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                <APText
                    variant='paragraph-large'
                    style={{
                        color: selectedCount != 0 || singleSelectedValue ? APPalette['brand-300'] : APPalette['grey-700']
                    }}
                >
                    {label}
                </APText>

                {
                    selectedCount != 0 &&
                    <APText style={{ borderRadius: '15px', backgroundColor: 'white', padding: '2px 10px', color: APPalette["brand-300"], }}>
                        {selectedCount}
                    </APText>
                }

                {
                    isOpen == true
                        ? <UpIcon size={16} />
                        : <DownIcon size={16} />

                }

            </APButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                PaperProps={{ style: { minWidth: "150px", paddingTop: '8px', marginTop: '4px' } }}
            >
                <APScrollView style={{ padding: "0px", minWidth: 150, minHeight: 50, maxHeight: 200 }}>
                    {(() => {
                        switch (type) {
                            case 'multi': return <APFilterMultiDropdown
                                key={type}
                                type='multi'
                                initialValue={multiSelectedValue}
                                options={options}
                                onChange={v => {
                                    setMultiSelectedValue(v);
                                    if (onChange) {
                                        onChange(v)
                                    }
                                }}
                            />;
                            case 'single': return <APFilterSingleDropdown
                                key={type}
                                type='single'
                                initialValue={singleSelectedValue}
                                options={options}
                                onChange={(v: string) => {
                                    setSingleSelectedValue(v)
                                    if (onChange) {
                                        onChange(v)
                                    }
                                }}
                            />;
                        }
                    }
                    )()}
                </APScrollView>
            </Popover>
        </div >
    </>
}