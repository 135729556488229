import { APInfo, APText } from "ap-components";
import { DemoItem, DemoPage, DemoSection } from "./Common";

export default function InformationTextPage() {
    return (
        <DemoPage label="Information Text">
            <DemoSection label="APInformationText">
                <DemoItem label="default" >
                    <APInfo tip="Lorem ipsum is the placeholder text" >Hover over me</APInfo>
                </DemoItem>
                <DemoItem label="typography='h2'" >
                    <APText variant="h2"> <APInfo tip="Lorem ipsum is the placeholder text" >Hover over me</APInfo></APText>
                </DemoItem>
                <DemoItem label="typography='paragraph-small'" >
                    <APText variant="paragraph-small"> <APInfo tip="Lorem ipsum is the placeholder text" >Hover over me</APInfo></APText>
                </DemoItem>
                <DemoItem label="typography='button-large'" >
                    <APText variant="button-large"> <APInfo tip="Lorem ipsum is the placeholder text" >Hover over me</APInfo></APText>
                </DemoItem>
                <DemoItem label="typography='link-medium'" >
                    <APText variant="link-medium"> <APInfo tip="Lorem ipsum is the placeholder text" >Hover over me</APInfo></APText>
                </DemoItem>
                <DemoItem label="typography='h2'" >
                    <APText variant="h2" >Information text will inherit the parent component's <APInfo tip="Set of defined text styles" >typography</APInfo>. Have a good day! </APText>
                </DemoItem>
                <DemoItem label="typography='paragraph-small'" >
                    <APText variant="paragraph-small" >Information text will inherit the parent component's <APInfo tip="Set of defined text styles" >typography</APInfo>. Have a good day! </APText>
                </DemoItem>
                <DemoItem label="typography='button-large'" >
                    <APText variant="button-large" >Information text will inherit the parent component's <APInfo tip="Set of defined text styles" >typography</APInfo>. Have a good day! </APText>
                </DemoItem>
                <DemoItem label="typography='link-medium'" >
                    <APText variant="link-medium" >Information text will inherit the parent component's <APInfo tip="Set of defined text styles" >typography</APInfo>. Have a good day! </APText>
                </DemoItem>
            </DemoSection>
        </DemoPage>
    )
}
