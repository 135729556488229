import Input from "@mui/material/Input";
import { debounce } from '@mui/material/utils';
import React, { useEffect, useRef, useState } from "react";
import { APPalette } from "../utils";
import { CloseIcon, SearchIcon } from "../icons";
import { APRow } from "../layout";
import APIconButton from "../icons/APIconButton";

export interface APDebounceTextProps {
    style?: React.CSSProperties;
    /**
     * @default Search
     */
    placeholder?: string;
    onChanged: (value: string) => void;
    /**
     * add icon before the text input cursor starts
     */
    icon?: JSX.Element;
    /**
     * disables the `icon` if `icon` is provided
     */
    disableIcon?: boolean;
    /**
     * @deprecated
     */
    value?: string

    initialValue?: string

    /**
     * @default 150
     * @description debounce time in milliseconds
     */
    debounceTime?: number
}

/**
 * @author `Awanish Raj`
 */
export function APDebounceText({ onChanged, placeholder = "Search", style, icon, disableIcon, initialValue, debounceTime = 150 }: APDebounceTextProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [hover, setHover] = useState(false);
    const [showDelete, setShowDelete] = useState(!!initialValue);

    const debounceSearch = debounce((text: string) => {
        onChanged(text);
    }, debounceTime);

    useEffect(() => {
        if (inputRef.current && initialValue) {
            inputRef.current.value = initialValue;
        }
    }, []);

    icon = icon ?? <SearchIcon size={16} color={APPalette["grey-500"]} />;

    return (
        <Input
            disableUnderline
            style={{
                height: "32px",
                padding: "6px 8px",
                fontSize: "14px",
                lineHeight: "20px",
                borderRadius: "8px",
                background: `${hover ? APPalette["grey-50"] : "#FFFFFF"}`,
                border: `${showDelete ? `1px solid ${APPalette["brand-200"]}` : `1px solid ${APPalette['grey-200']}`} `,
                boxShadow: `${showDelete ? "1px  #678FD3" : undefined}`,
                width: "212px",
                ...style,
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            type="text"
            placeholder={placeholder}
            inputProps={
                {
                    ref: inputRef,
                    style: {
                        padding: 0,
                        color: APPalette["grey-700"],
                        fontSize: "14px",
                        lineHeight: "20px"
                    }
                }
            }
            onChange={(event) => {
                debounceSearch(event.target.value);
                setShowDelete(event.target.value.length > 1 ? true : false)
            }}
            startAdornment={
                disableIcon ?
                    undefined
                    :
                    <APRow mainAxisSize="min" style={{ minWidth: "20px" }}>
                        {icon}
                    </APRow>
            }
            endAdornment={
                showDelete ?
                    <APRow mainAxisSize="min" style={{ minWidth: "20px" }}>
                        <APIconButton onClick={() => {
                            if (inputRef.current) {
                                inputRef.current.value = ""
                                debounceSearch("")
                                setShowDelete(false)
                            }
                        }}>
                            <CloseIcon size={16} color={APPalette["grey-500"]} />
                        </APIconButton>
                    </APRow>
                    :
                    undefined
            }
        />
    );
}
