import { APColumn, APFormFieldImage, APText } from "ap-components";
import { useWindowDimensions } from "ap-components/src/hooks/useWindowDimension";
import { RouteComponentProps } from "@gatsbyjs/reach-router";

export default function Mobile(props: RouteComponentProps) {
    var dimensions = useWindowDimensions();
    return (
        <APColumn mainAxisSize='max' crossAxisAlignment='stretch'>
            <APFormFieldImage
                label="PAN Photo"
                onChange={(v) => {
                    // if (v.length === 0) {
                    //     console.log("Proof required");
                    // }

                    // const fileSize = (v.length * (3 / 4)) - (v.endsWith("==") ? 2 : 1);
                    // if (v && fileSize > 2097152) {
                    //     console.log('File size should be less than 2Mb');
                    // }

                    // console.log(`File size ${fileSize}`)
                }}
            />
            <APText>
                <code>
                    {JSON.stringify(dimensions)}
                </code>
            </APText>
        </APColumn>
    )
}
