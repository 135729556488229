import { RouteComponentProps, useLocation, useNavigate } from "@gatsbyjs/reach-router";
import { TextFields, ViewQuilt, TouchApp, ViewList, ViewWeek, ViewComfy, Palette, TabRounded, BugReport, UnfoldMore, Notifications, Campaign, CallToAction, Navigation, Image, Flag, DesignServices, Create, HelpCenter, Tag } from "@mui/icons-material";
import { Paper, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { APRow, APExpanded, APColumn, APPadding, APText, APScrollView, APSizedBox, APPalette, APImage, CONST_DEBUG } from "ap-components";
import Accordion from "./Accordion";
import Badge from "./Badge";
import Buttons from "./Buttons";
import Forms from "./Forms";
import Layout from "./Layout";
import DialogsPage from "./DialogsPage";
import SnackBar from "./SnackBar";
import PalettePage from "./Palette";
import { Playground } from "./Playground";
import TabsPage from "./TabsPage";
import Tables from "./Tables";
import Typography from "./Typography";
import VirtualList from "./VirtualList";
import MediaCard from "./MediaCard";
import NavigationPage from "./Navigation";
import SectionMessagePage from "./SectionMessagePage";
import IconsPage from "./IconsPage";
import InputPage from "./InputPage";
import InformationTextPage from "./InformationTextPage";
import StepperPage from "./StepperPage";
import StepperAppPage from "./StepperAppPage";
import ColouredIconsPage from "./ColouredIconsPage";
import Tags from "./Tags";

export default function DemoPage(props: RouteComponentProps) {
    var location = useLocation();

    var slug = location?.pathname.replace("/demo/", "");
    var items = [
        { label: 'Typography', path: 'typography', widget: <Typography />, icon: <TextFields /> },
        { label: 'Palette', path: 'palette', widget: <PalettePage />, icon: <Palette /> },
        { label: 'Icons', path: 'icons', widget: <IconsPage />, icon: <DesignServices /> },
        { label: 'Coloured Icons', path: 'coloured_icons', widget: <ColouredIconsPage />, icon: <DesignServices /> },
        { label: 'Layout', path: 'layout', widget: <Layout />, icon: <ViewQuilt /> },
        { label: 'Buttons', path: 'buttons', widget: <Buttons />, icon: <TouchApp /> },
        { label: 'Tags', path: 'tags', widget: <Tags />, icon: <Tag /> },
        { label: 'Inputs', path: 'inputs', widget: <InputPage />, icon: <Create /> },
        { label: 'Forms', path: 'forms', widget: <Forms />, icon: <ViewList /> },
        { label: 'Stepper', path: 'steppers', widget: <StepperPage />, icon: <ViewList /> },
        { label: 'Stepper App', path: 'steppers_app', widget: <StepperAppPage />, icon: <ViewList /> },
        { label: 'Media Card', path: 'media_card', widget: <MediaCard />, icon: <Image /> },
        { label: 'Navigation', path: 'navigation', widget: <NavigationPage />, icon: <Navigation /> },
        { label: 'Tables', path: 'tables', widget: <Tables />, icon: <ViewWeek /> },
        { label: 'Virtual List', path: 'virtual_list', widget: <VirtualList />, icon: <ViewComfy /> },
        { label: 'Alert Bar', path: 'snackbar', widget: <SnackBar />, icon: <Campaign /> },
        { label: 'Dialogs', path: 'dialogs', widget: <DialogsPage />, icon: <CallToAction /> },
        { label: 'Badge', path: 'badge', widget: <Badge />, icon: <Notifications /> },
        { label: 'Accordion', path: 'accordion', widget: <Accordion />, icon: <UnfoldMore /> },
        { label: 'Tabs', path: 'tab', widget: <TabsPage />, icon: <TabRounded /> },

        { label: 'Information Text', path: 'information_text', widget: <InformationTextPage />, icon: <HelpCenter /> },
        // { label: 'Misc', path: 'misc', widget: <MiscellaneousPage />, icon: <MiscellaneousServices /> },
        // { label: 'Stepper', path: 'stepper', widget: <Stepper />, icon: <ViewList /> },
        { label: 'SectionMessage', path: 'section_message', widget: <SectionMessagePage />, icon: <Flag /> },
    ];

    if (CONST_DEBUG) {
        items.push({ label: 'Playground', path: 'playground', widget: <Playground />, icon: <BugReport /> })
    }

    var selectedPage = items.find(a => a.path === slug)?.widget;

    var navigate = useNavigate();

    return (
        <APRow mainAxisSize='max' crossAxisAlignment='stretch'>
            <APExpanded >
                <Paper>
                    <APColumn mainAxisSize='max' crossAxisAlignment='stretch'>
                        <APPadding padding={"24px"}>
                            <APText variant='h1' color={APPalette["brand-300"]} >ap-components</APText>
                        </APPadding>
                        <APExpanded>
                            <APScrollView>
                                <List>
                                    {items.map((item, index) => (
                                        <ListItem button key={item.path} onClick={() => {
                                            navigate(`/demo/${item.path}`);
                                        }} selected={item.path === slug}>
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText primary={item.label} />
                                        </ListItem>
                                    ))}
                                </List>
                            </APScrollView>
                        </APExpanded>
                    </APColumn>
                </Paper>
            </APExpanded>
            <APExpanded flex={4}>
                <APScrollView>
                    <APColumn>
                        <APSizedBox height="44px" />
                        {
                            selectedPage ? selectedPage :
                                <APColumn style={{
                                    backgroundColor: APPalette["brand-300"],
                                    padding: "131px 277px 131px 67px",
                                    width: "fit-content",
                                }}>
                                    <APColumn crossAxisAlignment="stretch">
                                        <APImage src="/ammunition.svg" />
                                        <APSizedBox height="12px" />
                                        <APText style={{ fontSize: "56px", color: APPalette.white, fontWeight: 600 }}>UI Library</APText>
                                        <APSizedBox height="12px" />
                                        <APText style={{ fontSize: "24px", color: APPalette.white, fontWeight: 600 }}>Components and Usage</APText>
                                        <APSizedBox height="50px" />
                                        <APText style={{ fontSize: "20px", color: APPalette.white, fontWeight: 600 }}>by AssetPlus</APText>
                                    </APColumn>
                                </APColumn>
                        }
                        <APSizedBox height="44px" />
                    </APColumn>
                </APScrollView >
            </APExpanded>

        </APRow>
    )
}
