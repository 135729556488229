import { useAPForm, APFormFieldController, APForm, APColumn, APFormFieldText, APFormFieldDropdown, APFormFieldImage, APFormFieldFile, sleep, showSnackbar, showErrorDialog, APFormSubmit, APRow, APOtp } from "ap-components";
import { useState } from "react";
import { DemoPage, DemoSection } from "./Common";

export default function Forms() {

    var control = useAPForm();
    const [image, setImage] = useState<string>();

    var [form] = useState({ // For adding default values of the form
        username: "",
        password: ""
    })

    var namedAmount = new APFormFieldController();
    var noNameAmount = new APFormFieldController();

    return <DemoPage label="Forms">

        <DemoSection label='Test Form'>
            <APForm control={control}>
                <APColumn crossAxisAlignment="stretch">
                    <APFormFieldText
                        autoFocus
                        label='Plain Text'
                        helperText='This is a plain text input'
                        onChanged={(v) => form.username = v}
                        validator={(v) => {
                            if (v.length === 0) {
                                return 'Input required';
                            }
                            return null;
                        }}
                    />

                    <APFormFieldDropdown label="Dropdown"
                        onChanged={v => {
                            console.log("Dropdown value changed ", v);
                            if (v) {
                                namedAmount.setText(v.toString());
                                noNameAmount.setText(v.toString());
                            }
                        }}
                        items={[
                            {
                                value: 1,
                                label: "Option 1"
                            },
                            {
                                value: 2,
                                label: "Option 2"
                            },
                            {
                                value: 3,
                                label: "Option 3"
                            },
                        ]}
                        helperText="This is a dropdown"
                        validator={(v) => {
                            if (!v) {
                                return "Please select an option";
                            }
                            return null;
                        }}
                    />

                    <APFormFieldImage
                        label="Image"
                        src={image}
                        onChange={setImage}
                        validator={(v) => {
                            console.log(v)
                            if (!v || v.length === 0) {
                                return "Please select a image"
                            }
                            return null;
                        }}
                    />

                    <APFormFieldFile
                        label="File Input"
                        helperText="This is a file input"
                        onChanged={(v) => { console.log(v) }}
                        validator={(file, base64) => {
                            if (!file) return "Select file";
                            if (file.size > 1000) {
                                return "File size bigger than 1 KB";
                            }
                            return null;
                        }}
                    />

                    <APRow>
                        <APOtp
                            digits={4}
                            validator={(v) => {
                                if (v.length != 4) {
                                    return "4 digit OTP required";
                                }
                                return null;
                            }}
                            onChanged={(v) => {
                                console.log("onChange ", v)
                            }}
                            onComplete={(v) => {
                                console.log("onComplete ", v)
                            }}
                            helperText="This is a OTP input"
                        />
                    </APRow>

                    <APFormSubmit
                        onClick={async () => {
                            if (await control.validate()) {
                                try {
                                    await sleep(1000);
                                    console.log({ namedAmount: namedAmount.getText(), noNameAmount: noNameAmount.getText() })
                                    // write the execution code here
                                    // showSnackbar is similar to snackbar of flutter, used for success response
                                    showSnackbar("Form has been submitted");
                                } catch (error) {
                                    // Shows snackbar but with red background to indicate error
                                    if (error instanceof Error)
                                        showErrorDialog(error.message);
                                }
                            }
                        }}
                    >
                        Submit
                    </APFormSubmit>
                </APColumn>
            </APForm>
        </DemoSection>
    </DemoPage>
}