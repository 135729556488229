import { APAttachmentCard, APColumn, APGrid, APRow, APSizedBox, APText } from "ap-components";
import APMediaCard from "ap-components/src/elements/APMediaCard"
import { DemoItem, DemoPage, DemoSection } from "./Common";
import moment from 'moment'

export default function MediaCard() {
    return (<DemoPage label="Media card">

        <DemoSection label='Article' style={{ width: "90%" }}>

            <DemoItem label='Article card'>
                <APRow gap="20px" crossAxisAlignment="stretch" style={{ flexWrap: "wrap" }}>
                    <APMediaCard
                        videoId={'https://www.youtube.com/watch?v=k7hyLNZkdbw'}
                        // videoId={'0n7AWxYCj9I'}
                        title={"Test Card is a media card with three variant three variant three variant Test Card is a media card with three variant three variant three variant nt Test Card is a media card with three variant three variant three variant"}
                        createdAt={new Date()}
                        author={'Research Team, AssetPlus'}
                        hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                        type={'article'}
                        category={'Investment Products'}
                        isNew={true}
                        isPopular={true}
                    />

                    <APMediaCard
                        videoId={''}
                        title={"Test Card is a media card with three variant three variant three variant Test Card is a media card with three variant three variant three variant nt Test Card is a media card with three variant three variant three variant"}
                        createdAt={new Date()}
                        views={10111}
                        onClick={() => {
                            console.log('i am clicked')
                        }}
                        imgUrl={'https://media.istockphoto.com/id/1406538311/photo/stone-platform-pedestal-in-tropical-forest-for-product-presentation-and-green-forest.jpg?s=1024x1024&w=is&k=20&c=aCFdwrFA4tVF1UYQg_qaYDJOAot2XmHDlMiXU_arrlk='}
                        hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                        type={'article'}
                        category={'Business Development'}
                        isNew={true}
                        isPopular={true}
                    />

                    <APMediaCard
                        videoId={''}
                        title={"Test Card is a media card with"}
                        createdAt={new Date()}
                        views={10111}
                        onClick={() => {
                            console.log('i am clicked')
                        }}
                        imgUrl={'https://media.istockphoto.com/id/1406538311/photo/stone-platform-pedestal-in-tropical-forest-for-product-presentation-and-green-forest.jpg?s=1024x1024&w=is&k=20&c=aCFdwrFA4tVF1UYQg_qaYDJOAot2XmHDlMiXU_arrlk='}
                        hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                        type={'article'}
                        category={'Insurance'}
                        isNew={true}
                    />
                    <APMediaCard
                        videoId={''}
                        title={"Test Card is a media card with"}
                        createdAt={new Date()}
                        views={10111}
                        onClick={() => {
                            console.log('i am clicked')
                        }}
                        imgUrl={'https://media.istockphoto.com/id/1406538311/photo/stone-platform-pedestal-in-tropical-forest-for-product-presentation-and-green-forest.jpg?s=1024x1024&w=is&k=20&c=aCFdwrFA4tVF1UYQg_qaYDJOAot2XmHDlMiXU_arrlk='}
                        hashtags={['Mutual fund ', 'Insurance', 'save water', 'hashtag1']}
                        type={'article'}
                        isNew={true}
                        isPopular={true}
                        onHashtagClick={(value: string) => {
                            console.log('value here', value)

                        }}
                    />

                    <APMediaCard
                        videoId={''}
                        title={"Test Card is a media card with three variant three variant three variant Test Card is a media card with three variant three variant three variant nt Test Card is a media card with three variant three variant three variant"}
                        createdAt={new Date()}
                        views={10111}
                        onClick={() => {
                            console.log('i am clicked')
                        }}
                        imgUrl={'https://media.istockphoto.com/id/1406538311/photo/stone-platform-pedestal-in-tropical-forest-for-product-presentation-and-green-forest.jpg?s=1024x1024&w=is&k=20&c=aCFdwrFA4tVF1UYQg_qaYDJOAot2XmHDlMiXU_arrlk='}
                        author={'Research Team, AssetPlus'}
                        hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                        type={'article'}
                        category={'Investment Products'}
                        isNew={true}
                    />

                    <APMediaCard
                        videoId={'0n7AWxYCj9I'}
                        title={"Test Card is a media card "}
                        createdAt={new Date()}
                        views={10111}
                        onClick={() => {
                            console.log('i am clicked')
                        }}
                        author={'Research Team, AssetPlus'}
                        hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                        type={'article'}

                    />
                </APRow>
            </DemoItem>
        </DemoSection>
        <DemoSection label='Poster' style={{ width: "90%" }}>
            <DemoItem label='Poster card'>
                <APRow gap="20px" style={{ flexWrap: "wrap" }} crossAxisAlignment="stretch">
                    <APMediaCard
                        videoId={''}
                        title={"Test Card is a media card with three variant three variant three variant Test Card is a media card with three variant three variant three variant nt Test Card is a media card with three variant three variant three variant"}
                        createdAt={new Date()}
                        views={500}
                        onClick={() => {
                            console.log('i am clicked')
                        }}
                        imgUrl={'https://media.istockphoto.com/id/1406538311/photo/stone-platform-pedestal-in-tropical-forest-for-product-presentation-and-green-forest.jpg?s=1024x1024&w=is&k=20&c=aCFdwrFA4tVF1UYQg_qaYDJOAot2XmHDlMiXU_arrlk='}
                        author={'Research Team, AssetPlus'}
                        hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                        type={'poster'}
                        category={'Investment Products'}
                        // isNew={true}
                        isPopular={true}
                    />
                    <APMediaCard
                        title={"Test Card is a media "}
                        createdAt={new Date()}
                        views={10111}
                        onClick={() => {
                            console.log('i am clicked')
                        }}
                        imgUrl={'https://media.istockphoto.com/id/1406538311/photo/stone-platform-pedestal-in-tropical-forest-for-product-presentation-and-green-forest.jpg?s=1024x1024&w=is&k=20&c=aCFdwrFA4tVF1UYQg_qaYDJOAot2XmHDlMiXU_arrlk='}
                        hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                        type={'poster'}
                        category={'Investment Products'}
                        isNew={true}
                        isPopular={true}
                    />

                    <APMediaCard
                        videoId={'0n7AWxYCj9I'}
                        title={"Test Card is a media card with three variant three variant three variant Test Card is a media card with three variant three variant three variant nt Test Card is a media card with three variant three variant three variant"}
                        createdAt={new Date()}
                        views={10111}
                        onClick={() => {
                            console.log('i am clicked')
                        }}
                        author={'Research Team, AssetPlus'}
                        hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                        type={'poster'}
                        category={'Investment Products'}
                        isPopular={true}
                    />
                    <APMediaCard
                        videoId={'0n7AWxYCj9I'}
                        title={"Test Card is a media card with three variant three variant three variant Test Card is a media card with three variant three variant three variant nt Test Card is a media card with three variant three variant three variant"}
                        createdAt={new Date()}
                        views={10111}
                        onClick={() => {
                            console.log('i am clicked')
                        }}
                        author={'Research Team, AssetPlus'}
                        hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                        type={'poster'}
                        category={'Investment Products'}
                        isNew={true}
                        isPopular={true}
                    />
                </APRow>

            </DemoItem>
        </DemoSection>
        <DemoSection label="default" style={{ width: "90%" }}>
            <DemoItem label='Search '>
                <APMediaCard
                    title={"Heading"}
                    createdAt={moment("2023-04-27").toDate()}
                    views={2600}
                    onClick={() => {
                        console.log('i am clicked')
                    }}
                    imgUrl={'https://media.istockphoto.com/id/1406538311/photo/stone-platform-pedestal-in-tropical-forest-for-product-presentation-and-green-forest.jpg?s=1024x1024&w=is&k=20&c=aCFdwrFA4tVF1UYQg_qaYDJOAot2XmHDlMiXU_arrlk='}
                    author={'Research Team, AssetPlus'}
                    isNew={true}
                    isPopular={true}
                />
                <APSizedBox height="20px" />
                <APMediaCard
                    videoId={'0n7AWxYCj9I'}
                    title={"Test Card is a media card with three variant three variant three "}
                    createdAt={new Date()}
                    author={'Research Team, AssetPlus'}
                    hashtags={['Mutual fund Mutual fund', 'Insurance', 'save water']}
                    category={'Investment Products'}
                    isNew={true}
                    isPopular={true}
                />
            </DemoItem>
        </DemoSection>

        <DemoSection label="Attachment Card" style={{ width: "90%" }}>
            <DemoItem label='default'>
                <APAttachmentCard
                    fileName={"Abhisek"} src={"adfadfasdf.pdf"}
                />
                <APSizedBox height="20px" />
                <APAttachmentCard
                    fileName={"Hello World"}
                    src={"asdfasdfasdf.png"}
                />
                <APSizedBox height="20px" />
                <APAttachmentCard
                    fileName={"Super long attachment file name right here"}
                    src={"asdfasdfasdf.adsfasdf"}
                />
            </DemoItem>
        </DemoSection>
    </DemoPage >
    );



}
