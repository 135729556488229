import React, { useContext, useEffect, useRef, useState } from 'react';
import { UploadFileOrImage } from '../helpers/UploadFileOrImage';
import { APFormContext } from "./APForm";
import { APFormControl, APFormFieldItem } from './Common';

interface IAPFormFileFileValue {
    file: File | null,
    base64: string | null
}

export interface IAPFormFieldFile {
    /**
     * @deprecated
     */
    style?: React.CSSProperties;
    label: string;
    helperText?: string;
    onChanged?: (file: File | null, base64: string | null) => void | Promise<void>;
    validator?: (file: File | null, base64: string | null) => string | null | Promise<string | null>;
    control?: APFormControl;
    accept?: string;
}

export function APFormFieldFile({
    style, label, helperText, onChanged, validator, control, accept
}: IAPFormFieldFile) {

    let inputRef = useRef<any>(null);


    var control2: APFormControl | undefined = useContext(APFormContext);
    const [value, setValue] = useState<IAPFormFileFileValue>({ file: null, base64: null });

    if (control !== undefined) {
        control2 = control;
    }

    const [error, setError] = useState<string | null>(null);

    async function checkValues(): Promise<boolean> {
        var errorMessage = null;
        if (validator) {
            errorMessage = await validator(value.file, value.base64);
        }
        setError(errorMessage);
        if (errorMessage) {
            inputRef.current?.scrollIntoView?.({ behavior: "smooth", block: "center" });
        }
        return !errorMessage;
    }

    var fieldItem: APFormFieldItem = {
        validate: checkValues,
        reset: () => {
            setValue({ file: null, base64: null });
        }
    };

    useEffect(() => {
        if (control2 !== undefined)
            control2.fields.add(fieldItem);
        return () => {
            if (control2 !== undefined)
                control2.fields.delete(fieldItem);
        };
    });

    useEffect(() => {
        (async () => {
            if (validator && error !== null) {
                setError(await validator(value.file, value.base64));
            }
            if (onChanged)
                onChanged(value.file, value.base64);
        })();
    }, [value]);

    return (
        <UploadFileOrImage
            label={label}
            ref={inputRef}
            error={!!error}
            helperText={error || helperText}
            onChange={({ file, base64 }) => {
                setValue({ file: file ?? null, base64: base64 ?? null })
            }}
            accept={accept}
        />
    )
}
