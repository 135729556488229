import { APColumn, APButton, showSnackbar } from 'ap-components';
import { DemoPage, DemoSection } from './Common';
import showAPAlertSnackBar from 'ap-components/src/dialogs/showAPAlertSnackBar';

export default function SnackBar() {

  return (
    <DemoPage label="Alert Bar">
      <DemoSection label='Snackbar' style={{ width: "400px" }}>
        <APColumn gap="12px" crossAxisAlignment='stretch'>
          <APButton onClick={() => showSnackbar("This is a success message")} >Positive</APButton>
          <APButton type='negative' onClick={() => showSnackbar("This is an error message", 'negative')} >Negative</APButton>
          <APButton type="secondary" onClick={() => showSnackbar("This is an informative message", 'info')} >Info</APButton>
          <APButton type="secondary" onClick={() => showSnackbar("This is a warning message", 'warning')} >Warning</APButton>
          <APButton onClick={() => showAPAlertSnackBar({
            content: "This is a placeholder text", onClickHeading: "View", onClick: () => {
              console.log("this is working")
            },
            intent: "info",
            emphasis: "subtle"
          })}>AlertBar info subtle</APButton>
          <APButton onClick={() => showAPAlertSnackBar({
            content: "This is a placeholder text", onClickHeading: "View", onClick: () => {
              console.log("this is working")
            },
            intent: "info",
            emphasis: "intense"
          })}>AlertBar info intense</APButton>
          <APButton onClick={() => showAPAlertSnackBar({
            content: "This is a placeholder text", onClickHeading: "View", onClick: () => {
              console.log("this is working")
            },
            intent: "positive",
            emphasis: "intense"
          })}>AlertBar positive intense</APButton>
          <APButton onClick={() => showAPAlertSnackBar({
            content: "This is a placeholder text", onClickHeading: "View", onClick: () => {
              console.log("this is working")
            },
            intent: "positive",
            emphasis: "subtle"
          })}>AlertBar positive subtle</APButton>
          <APButton onClick={() => showAPAlertSnackBar({
            content: "This is a placeholder text", onClickHeading: "View", onClick: () => {
              console.log("this is working")
            },
            intent: "info",
            emphasis: "subtle"
          })}>AlertBar info subtle</APButton>
          <APButton onClick={() => showAPAlertSnackBar({
            content: "This is a placeholder text", onClickHeading: "View", onClick: () => {
              console.log("this is working")
            },
            intent: "warning",
            emphasis: "subtle"
          })}>AlertBar waring subtle</APButton>
        </APColumn>
      </DemoSection>
    </DemoPage>
  )
}
