import MomentUtils from '@date-io/moment';
import DateRange from '@mui/icons-material/DateRange';
import Schedule from '@mui/icons-material/Schedule';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { APFormContext } from "./APForm";
import { APFormControl, APFormFieldItem } from './Common';


export function APFormFieldDateTime({
    margin = "normal", label = "Date", formatter = "DD/MM/YYYY hh:mm a", style, size, placeholder, initialValue, value, readOnly, helperText, onChanged, validator, control, disabled
}: {
    style?: React.CSSProperties;
    size?: "small" | "medium";
    label?: string;
    margin?: 'dense' | 'normal' | 'none';
    placeholder?: string;
    initialValue?: string;
    disabled?: boolean;
    formatter?: string;
    value?: string;
    readOnly?: boolean;
    helperText?: string;
    onChanged: (v: Moment | null) => any;
    validator?: (v: Moment | null) => string | null | Promise<string | null>;
    control?: APFormControl;
}) {

    let inputRef = useRef<any>(null);

    var control2: APFormControl | undefined = useContext(APFormContext);
    if (control !== undefined) {
        control2 = control;
    }

    var tempValue = initialValue ?? value;
    const [_value, setValue] = useState(tempValue ? moment(tempValue) : null);
    const [error, setError] = useState<string | null>(null);

    async function checkValues(): Promise<boolean> {
        var errorMessage: string | null = null;
        if (validator) {
            errorMessage = await validator(_value);
        }
        setError(errorMessage);
        if (errorMessage) {
            inputRef.current?.scrollIntoView?.({ behavior: "smooth", block: "center" });
        }
        return !errorMessage;
    }

    var fieldItem: APFormFieldItem = {
        validate: checkValues,
        reset: () => {
            setValue(initialValue ? moment(initialValue) : null);
        }
    };

    useEffect(() => {
        control2 && control2.fields.add(fieldItem);
        return () => {
            control2 && control2.fields.delete(fieldItem);
        };
    });

    useEffect(() => {
        (async () => {
            validator && error && setError(await validator(_value));
            onChanged && onChanged(_value);
        })();

    }, [_value]);

    return (
        <LocalizationProvider utils={MomentUtils} dateAdapter={MomentUtils}>
            <DateTimePicker
                // label={label}
                disabled={disabled}
                ref={inputRef}
                readOnly={readOnly}
                toolbarPlaceholder={placeholder}
                timeIcon={<Schedule htmlColor="white" />}
                dateRangeIcon={<DateRange htmlColor="white" />}
                // inputVariant="outlined"
                value={_value ? moment(_value) : null}
                inputFormat={formatter}
                onChange={(date: any) => {
                    if (date)
                        setValue(date);
                }}
                renderInput={function (props: any): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
                    // throw new Error('Function not implemented.');
                    return <TextField
                        {...props}
                        label={label}
                        placeholder={placeholder}
                        style={style}
                        size={size}
                        margin={margin}
                        error={!!error}
                        variant={"outlined"}
                        helperText={error || helperText}
                    ></TextField>;
                }} />
        </LocalizationProvider>
    );
}
