import { useState } from "react";
import { APColumn, APExpanded, APRow, APSizedBox } from "../layout";
import { APPalette } from "../utils";
import { APImage } from "./APImage";
import { APText } from "./APText";

export interface APAttachmentCardProps {
    /**
     * name of the icon/pdf 
     */
    fileName: string;
    src: string;
}


export function APAttachmentCard({ fileName, src }: APAttachmentCardProps) {
    const [hover, setHover] = useState(false);

    // Image logic here
    var ext = src.split(".").pop()?.toLowerCase() || "";
    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <APRow
                mainAxisAlignment="center"
                mainAxisSize="min"
                style={{
                    borderRadius: "8px",
                    padding: "8px",
                    maxWidth: "340px",
                    border: `1px solid  ${APPalette["grey-200"]}`,
                    background: `${hover ? APPalette["grey-100"] : "#FFFFFF"}`,
                }}
            >
                {
                    ext == "pdf" ?
                        <APImage height="48px" width="48px" src="/icons/icon-pdf.svg" />
                        :
                        <APImage fit src={src} height="48px" width="48px" />
                }

                <APSizedBox width="8px" />

                <APExpanded>
                    <APColumn mainAxisAlignment="center" crossAxisAlignment='start' >
                        <APText maxLines={1} variant='paragraph-large' style={{ color: APPalette['grey-700'] }} >
                            {fileName}
                        </APText>

                        <APText variant='paragraph-xsmall' style={{ color: APPalette['grey-500'] }}>
                            {

                                ext.toUpperCase()
                            }
                        </APText>
                    </APColumn>
                </APExpanded>

                <APSizedBox width="8px" />

                <APImage
                    width="20px"
                    height="20px"
                    src="/icons/icon-download.svg"
                    onClick={() => window.open(src)}
                />
            </APRow>
        </div>

    )
}