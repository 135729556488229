export class APEdgeInsets {
    static "all" = (value: number) => {
        return value + "px";
    }

    static "fromLTRB" = (left: number, top: number, right: number, bottom: number) => {
        return `${top}px ${right}px ${bottom}px ${left}px`;
    }

    static "symmetric" = ({ vertical = 0, horizontal = 0 }) => {
        return `${vertical}px ${horizontal}px`;
    }

    static "only" = ({ top = 0, right = 0, bottom = 0, left = 0 }) => {
        return `${top}px ${right}px ${bottom}px ${left}px`;
    }
}