import { APColumn, APAccordion, APText } from "ap-components";

export default function Accordion() {
  return (
    <APColumn style={{ padding: "40px", background: "#e5e5e5" }} crossAxisAlignment='stretch'>
      <APAccordion title="Hello World">
        <APText variant='paragraph-small' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur soluta commodi doloribus iusto deserunt fuga pariatur consectetur vel sunt sed dolores, expedita unde odio. Eveniet repellendus nostrum praesentium culpa, dignissimos illum sed hic quia assumenda? Modi, nulla veniam? Quos, accusantium nemo maxime vitae rerum molestias, a, porro totam qui necessitatibus laboriosam voluptatum consequatur. Aliquid provident ullam molestiae, odio omnis quas ad molestias. Vero, facere quis perspiciatis eligendi cupiditate nemo odio, fugit non sapiente vitae placeat qui quod. Laboriosam eius deleniti voluptas illo delectus autem esse odio consectetur! Corporis neque quia officia et accusamus, nostrum soluta quibusdam voluptatibus quod perspiciatis molestiae iste nobis, reiciendis dolorem rerum facilis vitae mollitia quis illum excepturi facere amet recusandae cupiditate velit? Animi ducimus molestiae itaque nostrum, necessitatibus quas quod? Hic nisi minima molestias necessitatibus magnam. Asperiores dignissimos illo officia autem. Molestias deleniti possimus voluptatum accusantium veniam? Iste excepturi accusamus necessitatibus maxime, fugit repellendus sapiente quod qui, soluta, perferendis minus quas odit magnam consectetur aliquam? Amet autem facere alias nemo ipsa placeat officia eligendi, eaque iste recusandae blanditiis, minus ducimus provident corrupti velit. Modi porro assumenda harum, cupiditate ipsam eveniet, possimus totam libero velit, aperiam commodi eius vel magni. Illo, quaerat! Ducimus assumenda nobis vel debitis?</APText>
      </APAccordion>
    </APColumn>
  )
}
