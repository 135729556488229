import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { APPalette } from "../utils";
import { DownIcon } from "../icons";
import { APButton } from "../elements";

export function APDropdownMenu<T>({
    options, initial, onSelect, style
}: {
    options: Array<T>;
    initial?: T;
    onSelect: (option: T) => any;
    style?: React.CSSProperties;
}) {

    const [anchorEl, setAnchorEl] = useState<any>(null);

    const [value, setValue] = useState(initial ?? options[0]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <APButton
            style={{ ...style }}
            size="small"
            type="secondary"
            trailing={<DownIcon size={16} color={APPalette["brand-300"]} />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
        >
            {value as React.ReactNode}
        </APButton>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
        >
            {
                options.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            onSelect(option);
                            setValue(option);
                            handleClose();
                        }}
                    >
                        {option + ""}
                    </MenuItem>
                ))
            }
        </Menu>
    </>;


}
