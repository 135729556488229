import { createRoot } from 'react-dom/client';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import DemoPage from './Demo';
import Mobile from './Mobile';
import theme from './theme';
import { CssBaseline } from '@mui/material';
import { CONST_DEBUG, DialogHelper } from 'ap-components';
import App from './app';

createRoot(document.querySelector('#root')!).render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <App />
      <DialogHelper key={'apDialogHelper'} />
    </ThemeProvider>
  </StyledEngineProvider>
);