import { APRow, APTag } from "ap-components";
import { DemoItem, DemoPage, DemoSection } from "./Common";

export default function Tags() {
    return (
        <DemoPage label="Tags">
            <DemoSection label='Positive'>
                <DemoItem label="Subtle" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small" intent="positive">Label</APTag>
                        <APTag size="medium" intent="positive">Label</APTag>
                        <APTag size="large" intent="positive">Label</APTag>
                    </APRow>
                </DemoItem>
                <DemoItem label="Intense" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small" intent="positive" emphasis="intense">Label</APTag>
                        <APTag size="medium" intent="positive" emphasis="intense">Label</APTag>
                        <APTag size="large" intent="positive" emphasis="intense">Label</APTag>
                    </APRow>
                </DemoItem>
            </DemoSection>
            <DemoSection label='Negative'>
                <DemoItem label="Subtle" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small" intent="negative">Label</APTag>
                        <APTag size="medium" intent="negative">Label</APTag>
                        <APTag size="large" intent="negative">Label</APTag>
                    </APRow>
                </DemoItem>
                <DemoItem label="Intense" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small" intent="negative" emphasis="intense">Label</APTag>
                        <APTag size="medium" intent="negative" emphasis="intense">Label</APTag>
                        <APTag size="large" intent="negative" emphasis="intense">Label</APTag>
                    </APRow>
                </DemoItem>
            </DemoSection>
            <DemoSection label='Warning'>
                <DemoItem label="Subtle" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small" intent="warning">Label</APTag>
                        <APTag size="medium" intent="warning">Label</APTag>
                        <APTag size="large" intent="warning">Label</APTag>
                    </APRow>
                </DemoItem>
                <DemoItem label="Intense" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small" intent="warning" emphasis="intense">Label</APTag>
                        <APTag size="medium" intent="warning" emphasis="intense">Label</APTag>
                        <APTag size="large" intent="warning" emphasis="intense">Label</APTag>
                    </APRow>
                </DemoItem>
            </DemoSection>
            <DemoSection label='Info'>
                <DemoItem label="Subtle" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small" intent="info">Label</APTag>
                        <APTag size="medium" intent="info">Label</APTag>
                        <APTag size="large" intent="info">Label</APTag>
                    </APRow>
                </DemoItem>
                <DemoItem label="Intense" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small" intent="info" emphasis="intense">Label</APTag>
                        <APTag size="medium" intent="info" emphasis="intense">Label</APTag>
                        <APTag size="large" intent="info" emphasis="intense">Label</APTag>
                    </APRow>
                </DemoItem>
            </DemoSection>
            <DemoSection label='Default'>
                <DemoItem label="Subtle" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small">Label</APTag>
                        <APTag size="medium">Label</APTag>
                        <APTag size="large">Label</APTag>
                    </APRow>
                </DemoItem>
                <DemoItem label="Intense" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APTag size="small" emphasis="intense">Label</APTag>
                        <APTag size="medium" emphasis="intense">Label</APTag>
                        <APTag size="large" emphasis="intense">Label</APTag>
                    </APRow>
                </DemoItem>
            </DemoSection>
        </DemoPage>
    )
}