import Box from "@mui/material/Box"
import { useEffect, useState } from "react"
import { APCard, APImage } from "../elements"
import { APInkWell } from "../elements/APInkWell"
import { APText } from "../elements/APText"
import { useFilePicker } from "../hooks"
import { APColumn, APRow, APSizedBox } from "../layout"
import { convertBase64 } from "./ShowImage"
import { APPalette } from "../utils"

export interface APFileUploadProps {
    label: string
    error?: boolean
    helperText?: string
    accept?: string;
    ref?: any;
    onChange: ({ file, base64 }: { file?: File, base64?: string }) => void,
}

function bytesForHuman(bytes: number) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let i = 0

    for (i; bytes > 1024; i++) {
        bytes /= 1024;
    }

    return Math.ceil(bytes).toFixed(0) + ' ' + units[i]
}

export function UploadFileOrImage({ label, onChange, error, helperText, accept, ref }: APFileUploadProps) {
    const [file, setFile] = useState<File>();
    var { files, onClick, HiddenFileInput } = useFilePicker();

    useEffect(() => {
        if (files && files[0]) {
            setFile(files[0])
        }
    }, [files])

    useEffect(() => {
        onChanged()
    }, [file])

    async function onChanged() {
        let base64: string | undefined = undefined;
        if (file) {
            base64 = await convertBase64(file) as string;
        }
        onChange({
            file,
            base64
        })
    }

    function remove() {
        setFile(undefined);
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            ref={ref}
            onDragOver={(event) => {
                event.preventDefault();
            }}
            onDrop={(event) => {
                event.preventDefault()
                setFile(event.dataTransfer.files[0])
            }}
        >
            <APText style={{ color: APPalette["grey-500"], fontSize: "16px", lineHeight: "24px" }}  >
                {label}
            </APText>
            <APSizedBox height="8px" />
            {
                !file &&
                <>
                    <APInkWell onClick={onClick}>
                        <APColumn style={{ border: `1px dashed ${error ? APPalette["negative-200"] : APPalette["grey-500"]}`, borderRadius: "8px", padding: "32px", background: APPalette["grey-50"] }}>
                            <APImage src="/icons/icon-upload.svg" />
                            <APSizedBox height="16px" />
                            <APText variant="paragraph-small" center color={APPalette["grey-700"]}>
                                Drag-and-drop file, or <span style={{ color: APPalette["brand-300"] }}>browse computer</span>
                            </APText>
                        </APColumn >
                    </APInkWell>
                    <HiddenFileInput accept={accept} />
                </>
            }
            {
                file && file.type.includes("image/") &&
                <PreviewImage file={file} remove={remove} error={error} />
            }
            {
                file && !file.type.includes("image/") &&
                < PreviewFile file={file} remove={remove} error={error} />
            }
            {
                helperText &&
                <>
                    <APSizedBox height="4px" />
                    <APText variant="paragraph-small" color={error ? APPalette["negative-200"] : APPalette["grey-500"]}>{helperText}</APText>
                </>
            }
            <APSizedBox height="8px" />
        </Box>
    )
}

export function PreviewImage({ file, remove, error }: { file: File, remove: () => void, error?: boolean }) {
    const image = URL.createObjectURL(file);
    return (
        <APCard style={{ borderRadius: "8px", padding: "8px", border: `1px solid ${error ? APPalette["negative-200"] : APPalette["grey-300"]}` }}>
            <APRow gap="8px" crossAxisAlignment="start">
                <APSizedBox height="100px" width="100px" style={{ background: "#eee" }}>
                    <APImage
                        style={{ borderRadius: "4px", maxWidth: "100px", maxHeight: "100px" }}
                        src={image}
                    />
                </APSizedBox>
                <APColumn crossAxisAlignment="start" mainAxisAlignment="start" gap="4px">
                    <APText variant="paragraph-large" color={APPalette["grey-700"]}>{file.name}</APText>
                    <APText variant="paragraph-small" color={APPalette["grey-500"]}>{bytesForHuman(file.size)}</APText>
                </APColumn>
                <APImage onClick={remove} src={"/icons/icon-cancel.svg"} />
            </APRow>
        </APCard>
    )
}

export function PreviewFile({ file, remove, error }: { file: File, remove: () => void, error?: boolean }) {
    return (
        <APCard style={{
            padding: "8px",
            border: `1px solid ${error ? APPalette["negative-200"] : APPalette["grey-300"]}`,
            borderRadius: "8px"
        }}>
            <APRow mainAxisAlignment="spaceBetween" gap="8px">
                <APRow mainAxisSize="max" gap="8px">
                    {
                        error ?
                            <APImage src="/icons/icon-error-file.svg" />
                            :
                            <APImage src="/icons/icon-file.svg" />
                    }
                    <APText style={{ wordBreak: "break-all" }} maxLines={2} variant="paragraph-large" color={APPalette["grey-700"]}>{file.name}</APText>
                    <APText variant="paragraph-small" color={APPalette["grey-500"]}>{bytesForHuman(file.size)}</APText>
                </APRow>
                <APImage onClick={remove} src="/icons/icon-cancel.svg" />
            </APRow>
        </APCard >
    )
}
