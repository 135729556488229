import { useEffect, useRef, useState } from "react";
import { APPalette } from "../utils";
import { APText } from "./APText";
import { APSizedBox } from "../layout";
import { useWindowDimensions } from "../hooks/useWindowDimension";
import { APPills } from "./APPills";

/**
 * @limitations
 * 1. All tabs take the same width
 */

interface CupertinoTabItemProp {
    label: string,
    value: string,
    count?: number,
}

interface APCupertinoTabsProps {
    initialValue?: string,
    items: CupertinoTabItemProp[]
    onChange: (value: string) => void | Promise<void>
    /**
     * @default "light"
     */
    variant?: 'light' | 'dark'
}

/**
 * @deprecated 
 */
export function APCupertinoTabs({ initialValue, items, variant = 'light', onChange }: APCupertinoTabsProps) {
    return (
        <APPills
            initialValue={initialValue}
            onChange={onChange}
            hideAll
            items={
                items.map(e => ({
                    title: e.label,
                    value: e.value,
                    count: e.count
                }))
            }
        />
    )

    const [selectedTab, setSelectedTab] = useState<string | undefined>(initialValue);
    const [selectedChildWidth, setSelectedChildWidth] = useState(0)
    const [selectedChildPositionInXAxis, setSelectedChildPositionInXAxis] = useState(0)

    let railRef = useRef<HTMLDivElement>(null)

    /// This will help in re-calculating the width of the selected tab
    /// Since this is used mostly in full width, any change in screen width will trigger re-render
    var { width } = useWindowDimensions();

    useEffect(() => {
        if (onChange) {
            onChange(selectedTab || "");
        }

        let allTabs = railRef?.current?.children;
        if (allTabs) {
            let indexOfSelectedTab = items.findIndex(e => e.value === selectedTab);
            if (indexOfSelectedTab > -1) {
                let selectedChildTab = allTabs[indexOfSelectedTab]
                let childWidth = selectedChildTab.clientWidth;
                setSelectedChildWidth(childWidth)

                /// Use this approach to accommodate dynamic width of each tab
                /// let startPosition = 2;
                /// for (let index = 0; index < indexOfSelectedTab; index++) {
                ///     const element = allTabs[indexOfSelectedTab];
                ///     startPosition += element.clientWidth
                /// }

                /// Easier approach
                /// Using +2 in the end for correcting the position of padding of the selected Tab
                setSelectedChildPositionInXAxis(childWidth * indexOfSelectedTab + 2);
            }
        }
    }, [selectedTab, width, items.length])

    return (
        <div style={{
            backgroundColor: variant === 'light' ? APPalette["grey-100"] : APPalette.white,
            borderRadius: "12px",
            padding: "2px",
            border: `2px solid ${variant === 'light' ? APPalette["grey-100"] : APPalette.white}`,
            position: "relative",
            width: "100%",
            zIndex: 1
        }}>
            <div style={{ display: "flex" }} ref={railRef}>
                {
                    items.map((tab, index) => (
                        <CupertinoTab
                            tab={tab}
                            isActive={tab.value === selectedTab?.split("/")[0]}
                            onSelect={setSelectedTab}
                            key={index}
                        />
                    ))
                }
            </div>
            <div style={{
                position: "absolute",
                height: "28px",
                width: selectedChildWidth,
                backgroundColor: variant === "light" ? APPalette.white : APPalette["grey-100"],
                borderRadius: "8px",
                top: "2px",
                left: selectedChildPositionInXAxis + "px",
                zIndex: -1,
                transition: "all .2s"
            }} />
        </div>
    )
}

function CupertinoTab({ tab, isActive, onSelect }: { tab: CupertinoTabItemProp, isActive: boolean, onSelect: (args: any) => void }) {
    const [onHover, setOnHover] = useState(false)

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                padding: "4px 8px",
                borderRadius: "8px",
                cursor: "pointer",
                transition: "all 0.15s",
                width: "100%",
                /// Disabling text selection
                userSelect: "none"
            }}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            onClick={() => onSelect(tab.value)}
        >
            <APText
                variant={(isActive || onHover) ? "h4" : "paragraph-small"}
                maxLines={1}
                style={{
                    transition: "all 0.15s",
                }}
            >
                {tab.label}
            </APText>
            {
                // Badge
                ((tab.count ?? 0) > 0) &&
                <APSizedBox
                    style={{
                        backgroundColor: APPalette["grey-200"],
                        borderRadius: "8px",
                        padding: "0 6px",
                    }}
                >
                    <APText variant="paragraph-xsmall" color={APPalette["grey-700"]} >{tab.count}</APText>
                </APSizedBox>
            }
        </div>
    )
}