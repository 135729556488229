import { Alert, Snackbar } from '@mui/material';
import { APPalette, sleep } from '../utils';
import { APAlign, APRow } from '../layout';
import { CloseIcon, TickRoundIcon } from '../icons';
import { APInkWell, APText } from '../elements';
import { showDialog } from '.';
import { APIcon } from '../icons/APIcon';

type Intent = 'positive' | 'negative' | 'warning' | 'info';
type Emphasis = 'subtle' | 'intense';

export type showAPAlertSnackBarProps = {
    content: string,
    intent?: Intent,
    emphasis?: Emphasis,
    onClick?: () => any,
    onClickHeading?: string
}

async function showAlertSnackbar({ content, intent, emphasis, onClick, onClickHeading }: showAPAlertSnackBarProps) {
    let newSeverity: 'success' | 'info' | 'warning' | 'error';
    let backgroundColor = APPalette['positive-100'];
    let icon = <TickRoundIcon color={APPalette['positive-400']} />
    let textColor = APPalette['grey-700'];


    switch (intent) {
        case 'positive': newSeverity = 'success';
            break;
        case 'negative': newSeverity = 'error';
            break;
        case 'info': newSeverity = 'info';
            break;
        case 'warning': newSeverity = 'warning';
            break;
        default:
            newSeverity = 'success';
            break;
    }
    switch (intent) {
        case "positive":
            if (emphasis === "subtle") {
                backgroundColor = APPalette['positive-100'];
                icon = <APIcon icon="tickRound" color={APPalette['positive-400']} />
                textColor = APPalette['grey-700']
            }
            else {
                backgroundColor = APPalette['positive-400'];
                icon = <APIcon icon="checkIntense" />
                textColor = APPalette.white
            }
            break;
        case "negative":
            if (emphasis === "subtle") {
                backgroundColor = APPalette['negative-100'];
                icon = <APIcon icon='negativeSubtle' />
                textColor = APPalette['grey-700']
            }
            else {
                backgroundColor = APPalette['negative-400'];
                icon = <APIcon icon="negativeIntense" />
                textColor = APPalette.white
            }
            break;
        case "warning":
            if (emphasis === "subtle") {
                backgroundColor = APPalette['warning-100'];
                textColor = APPalette['grey-700']
                icon = <APIcon icon="waringSubtle" />


            }
            else {
                backgroundColor = APPalette['warning-300'];
                textColor = APPalette['grey-700']
                icon = <APIcon icon="warningIntense" />
            }
            break;
        case "info":
            if (emphasis === "subtle") {
                backgroundColor = APPalette['info-100'];
                textColor = APPalette['grey-700']
                icon = <APIcon icon="infoSubtle" />
            }
            else {
                backgroundColor = APPalette['info-400'];
                textColor = APPalette.white
                icon = <APIcon icon="infoIntense" />
            }
            break;

        default:
            break;
    }
    var close = showDialog(
        <APAlign align='bottomCenter' style={{ width: "100vw", }}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={true}
                autoHideDuration={3000}
            >
                <Alert
                    style={{ width: "100%", background: backgroundColor, padding: "8px 16px", borderRadius: "0px" }}
                    severity={newSeverity}
                    onClose={() => close()}
                    icon={icon}
                    action={<APRow mainAxisAlignment="center" crossAxisAlignment='start' gap='8px' ><APText color={textColor} variant='link-medium' onClick={() => {
                        if (onClick) { onClick() }
                        close()
                    }}>{onClickHeading}</APText><APInkWell onClick={() => close()}> <CloseIcon size={20} color={textColor} /> </APInkWell></APRow>}
                >
                    <APText variant='paragraph-small' color={textColor}>{content}</APText>
                </Alert>
            </Snackbar>
        </APAlign>
    );
    await sleep(3000);
    close();
}

function showAlertSnackBarConfirmation(message: string, props?: Omit<showAPAlertSnackBarProps, "content">): Promise<void>
function showAlertSnackBarConfirmation(props: showAPAlertSnackBarProps): Promise<void>
function showAlertSnackBarConfirmation(arg1: unknown, args2?: unknown): Promise<void> {
    if (typeof arg1 === "string") {
        return showAlertSnackbar({ content: arg1, ...args2 as Omit<showAPAlertSnackBarProps, "content"> })
    }
    return showAlertSnackbar(arg1 as showAPAlertSnackBarProps)
}

export default showAlertSnackBarConfirmation