import { useState, } from "react";
import { APText } from "../elements";
import { APIcon } from "../icons/APIcon";
import { APRow, APColumn, APSizedBox } from "../layout";
import { APPalette } from "../utils";
import { RiderProps } from "./APCheckboxRider";
import { APFormFieldDropdown } from "./APFormFieldDropdown";
interface APRadioRiderProps {
    riders: APRiderItem[],
    onChanged: (value: RiderProps | null) => void,
}
export interface APRiderItem {
    label: string,
    value: string,
    description?: string | string[],
    dropDownItem?: DropdownProps,
    amount: number,
}
export interface DropdownProps {
    title: string;
    dropDownItem?: ItemProps[];
    initialValue?: string | undefined,
}
interface ItemProps {
    value: string,
    label: string,
}
interface RadioCardProps {
    isSelected: boolean,
    onSelect: (value: string, sumInsuredValue?: string) => void,
    rider: APRiderItem,
    handleDropdown: (value: string, sumInsuredValue?: string) => void;
}

export default function APRadioRider(props: APRadioRiderProps) {
    const [riderValue, setRiderValue] = useState<string | null>(null);
    const handleChange = (value: string, sumInsuredValue?: string) => {
        if (riderValue === value) {
            setRiderValue(null);
            props.onChanged!(null)
        } else {
            setRiderValue(value);
            props.onChanged({ value: value, sumInsuredValue: sumInsuredValue ?? undefined })
        }
    };
    const handleDropdown = (value: string, sumInsuredValue?: string) => {
        props.onChanged({ value: value, sumInsuredValue: sumInsuredValue ?? '' })
    }

    return <div style={{ position: 'relative' }}>
        <div className="apRadioRiderContainer" >
            {
                props.riders.map((item) =>
                    <RadioCard
                        key={item.value}
                        onSelect={handleChange}
                        isSelected={riderValue === item.value}
                        rider={item}
                        handleDropdown={handleDropdown}
                    />
                )
            }
        </div>
        <VariantTag />
    </div>
}
export function RadioCard({ isSelected, onSelect, rider: item, handleDropdown }: RadioCardProps) {
    const [dropdownValue, setDropdownValue] = useState<string | undefined>(undefined)
    const handleSelect = () => {
        if (item.dropDownItem) {
            onSelect(item.value, dropdownValue == undefined ? item.dropDownItem.initialValue : dropdownValue);
        } else {
            onSelect(item.value);
        }
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "stretch",
            }}
            className={`apRadioRiderItem ${isSelected ? 'active' : undefined}`}
        >
            <APRow mainAxisAlignment="start" crossAxisAlignment="start" >
                <APRow onClick={() => {
                    handleSelect();
                }} mainAxisSize='min'>
                    <APIcon icon={isSelected ? 'checkCircle' : 'circleOutline'} size={20} color={isSelected ? APPalette["brand-300"] : APPalette["grey-400"]} />
                </APRow>
                <APSizedBox width="8px" />
                <APColumn crossAxisAlignment="start">
                    <APText variant='h4'>
                        {item.label}
                    </APText>
                    <APSizedBox height="4px" />
                    {
                        typeof item.description == 'string'
                            ?
                            <APText variant='paragraph-small'>{item.description}</APText>
                            :
                            item.description?.map((description: string, index) => {
                                return <APRow key={index} crossAxisAlignment="start">
                                    <ul style={{ color: APPalette["grey-600"] }}>
                                        <li><APText variant='paragraph-small'>{description}</APText></li>
                                    </ul>
                                </APRow>
                            })
                    }
                    {
                        item.dropDownItem != null &&
                        <>
                            <APSizedBox height="8px" />
                            <APFormFieldDropdown
                                hideCloseButton
                                initialValue={item.dropDownItem.initialValue}
                                style={{ width: '256px' }}
                                onChanged={(value) => {
                                    if (value != null) {
                                        setDropdownValue(value)
                                        if (isSelected) {
                                            handleDropdown(item.value, value);
                                        }
                                    }
                                }}
                                label={item.dropDownItem?.title ?? ''}
                                items={item.dropDownItem?.dropDownItem ?? []} />
                        </>
                    }
                </APColumn>
            </APRow>
            <APColumn crossAxisAlignment="end" mainAxisSize="min" style={{ maxWidth: '150px' }}>
                <APText variant='paragraph-xsmall'>Premium</APText>
                <APText variant="h3">{item.amount >= 0 ? '+ ' : '- '}{item.amount.currency()}</APText>
            </APColumn>
        </div>
    )
}

function VariantTag() {
    return <APRow mainAxisSize="min"
        style={{
            padding: '4px 8px',
            color: APPalette["grey-500"],
            position: 'absolute',
            border: '1px solid #E2E6EF',
            top: '-14px',
            left: '40px',
            borderRadius: '6px',
            backgroundColor: APPalette["grey-50"]
        }}>
        Select a variant
    </APRow >
}


