import { APCupertinoTabs, APPills, APSizedBox, NArray } from 'ap-components'
import { DemoItem, DemoPage, DemoSection } from './Common'

export default function TabsPage() {
    return (
        <DemoPage label="Tabs">
            <DemoSection label='Pill Tabs'>
                <DemoItem label='With All' >
                    <APPills
                        initialValue='folder'
                        onChange={(value) => {
                            console.log("Pill tab changed to " + value)
                        }}
                        items={[
                            { count: 12, title: "Folder", value: "folder", apIcon: "folder" },
                            { count: 234, title: "Books", value: "book", apIcon: "book" },
                            { count: 98374, maxCount: 999, title: "Misc", value: "misc" },
                        ]}
                    />
                </DemoItem>
                <DemoItem label='Without All' >
                    <code> hideAll </code>
                    <APSizedBox height='8px' />
                    <APPills
                        hideAll
                        initialValue='folder'
                        onChange={(value) => {
                            console.log("Pill tab changed to " + value)
                        }}
                        items={[
                            { count: 12, title: "Folder", value: "folder", apIcon: "folder" },
                            { count: 234, title: "Books", value: "book", apIcon: "book" },
                            { count: 98374, maxCount: 999, title: "Misc", value: "misc" },
                        ]}
                    />
                </DemoItem>
                <DemoItem label='Deprecated'>
                    <code> deprecated </code>
                    <APSizedBox height='8px' />

                    <APPills
                        initialValue='tab1'
                        onChange={(value) => {
                            console.log("Pill tab changed to " + value)
                        }}
                        items={[
                            { count: 1, title: "Pill Button 1", value: "tab1", icon: '/icons/icon-cancel.svg' },
                            { count: 123, title: "Pill Button 2", value: "tab2", icon: '/icons/icon-avatar.svg' },
                            { count: 0, title: "Pill Button 3", value: "tab3", icon: '/icons/icon-delete.svg' },
                        ]}
                    />
                </DemoItem>
            </DemoSection>

            <DemoSection label='Cupertino Tabs - @deprecated'>
                <code>deprecated</code>
                <APSizedBox height='8px' />
                <APCupertinoTabs
                    initialValue='0'
                    items={
                        NArray(6).map((e, i) => ({
                            label: `Tab super long text ${i + 1}`,
                            value: i.toString(),
                            count: i * 10 + 6
                        }))
                    }
                    onChange={(value) => {
                        console.log("Cupertino tab changed to " + value)
                    }}
                />
            </DemoSection>
        </DemoPage>
    )
}
