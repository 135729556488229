import { adaptV4Theme, createTheme } from '@mui/material/styles';
import { styles } from 'ap-components'

const theme = createTheme({
    ...adaptV4Theme(styles),
    palette: {
        ...styles.palette,
        background: {
            default: '#f2f1f6'
        }
    }
});

export default theme
