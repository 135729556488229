import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { APRow } from "../layout/APFlex";




export function APMenu({ options, label, icon }: { options: { label: string; callback: () => any; }[]; icon: JSX.Element; label: string; }) {
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <APRow
            mainAxisSize="min"
            onClick={(event) => {
                setAnchorEl(event.currentTarget);
            }}
            crossAxisAlignment="center"
        >
            <span style={{ fontSize: "15px", fontWeight: 700, lineHeight: "20px", letterSpacing: "0.58px", color: "#828282" }}>{label}</span>
            {icon}
        </APRow>
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {options.map(option => {
                return <MenuItem key={option.label} onClick={() => {
                    handleClose();
                    option.callback();
                }}>{option.label}</MenuItem>;
            })}
        </Menu>
    </>;
}
