import { APGrid, APStack, APAlign, APCenter, APText, APSizedBox, MainAxisSize, MainAxisAlignment, CrossAxisAlignment, APExpanded, APRow, APColumn, APSwitcher } from "ap-components";
import { useState } from "react";
import { DemoPage, DemoSection } from "./Common";

export default function Layout() {

    return <DemoPage label="Layout">

        <RowDemo></RowDemo>
        <ColumnDemo></ColumnDemo>

        <DemoSection label="Grid">
            <APGrid spacing={2} count={6}>
                <Square />
                <Square />
                <Square />
                <Square />
                <Square />
                <Square />
                <Square />
                <Square />
                <Square />
                <Square />
                <Square />
                <Square />
                {/* ARRAY METHOD BELOW, Note: unquie "key" should be passed down to the child to avoid duplication and NArray is customized global function that returns size "n" array of type number  */}
                {/* {     
                        NArray(12).map(obj => <Square key={obj} />)
                    } */}
            </APGrid>
        </DemoSection>


        <DemoSection label="Stack">
            <APStack style={{ height: "300px", width: "100%", background: getRandomColor() }} >
                <APAlign align="fill" style={{ background: getRandomColor() }}>
                    <APCenter>
                        <APText>(1) 'fill' component</APText>
                    </APCenter>
                </APAlign>
                <APAlign noClone align="bottomRight" style={{ background: getRandomColor(), width: "100px", height: "100px", }}>
                    <APCenter>
                        <APText style={{ textAlign: "center" }}> (2) 'bottomRight' component</APText>
                    </APCenter>
                </APAlign>
                <APAlign noClone align="bottomCenter" style={{ background: getRandomColor(), width: "100px", height: "100px", }}>
                    <APCenter>
                        <APText style={{ textAlign: "center" }}> (3) 'bottomCenter' component</APText>
                    </APCenter>
                </APAlign>
                <APAlign noClone align="topRight" style={{ background: getRandomColor(), width: "100px", height: "100px", top: "100px", right: "100px" }}>
                    <APCenter>
                        <APText style={{ textAlign: "center" }}> (4) 'topRight' component</APText>
                    </APCenter>
                </APAlign>
            </APStack>
            <APSizedBox height="12px" />
            <APText variant="subtext">Number inside the brackets represent z-index of the component, or their position in the stack </APText>
        </DemoSection>
    </DemoPage>
}

function RowDemo() {
    const [mainAxisSize, setMainAxisSize] = useState<MainAxisSize>('min')
    const [mainAxisAlignment, setMainAxisAlignment] = useState<MainAxisAlignment>('spaceAround')
    const [crossAxisAlignment, setCrossAxisAlignment] = useState<CrossAxisAlignment>('center');

    return (
        <DemoSection label="Row" style={{ height: "350px" }}>
            <APExpanded>
                <APRow crossAxisAlignment='stretch' mainAxisSize='max' style={{ height: "350px" }}>
                    <APExpanded flex={1}>
                        <APColumn mainAxisSize='max' mainAxisAlignment='spaceAround'>
                            <APText variant='cardTitle'>mainAxisSize</APText>
                            <APSwitcher width="100%" options={['min', 'max'] as MainAxisSize[]} onSelect={setMainAxisSize}></APSwitcher>
                            <APText variant='cardTitle'>mainAxisAlignment</APText>
                            <APSwitcher width="100%" options={['spaceAround', 'spaceBetween', 'spaceEvenly', 'start', 'end', 'center'] as MainAxisAlignment[]} onSelect={setMainAxisAlignment}></APSwitcher>
                            <APText variant='cardTitle'>crossAxisAlignment</APText>
                            <APSwitcher width="100%" options={['center', 'start', 'end', 'stretch', 'baseline'] as CrossAxisAlignment[]} onSelect={setCrossAxisAlignment}></APSwitcher>
                        </APColumn>
                    </APExpanded>
                    <APExpanded flex={1.5}>
                        <APRow mainAxisSize={mainAxisSize} mainAxisAlignment={mainAxisAlignment} crossAxisAlignment={crossAxisAlignment}>
                            <Square></Square>
                            <Square size={30}></Square>
                            <Square size={20}></Square>
                            <Square size={50}></Square>
                        </APRow>
                    </APExpanded>
                </APRow>
            </APExpanded>
        </DemoSection>
    );
}

function ColumnDemo() {

    const [mainAxisSize, setMainAxisSize] = useState<MainAxisSize>('min')
    const [mainAxisAlignment, setMainAxisAlignment] = useState<MainAxisAlignment>('spaceAround')
    const [crossAxisAlignment, setCrossAxisAlignment] = useState<CrossAxisAlignment>('center');

    return <DemoSection label="Column" style={{ height: "350px" }}>
        <APExpanded>
            <APRow crossAxisAlignment='stretch' mainAxisSize='max'  >
                <APExpanded flex={1}>
                    <APColumn mainAxisSize='max' mainAxisAlignment='spaceAround'>
                        <APText variant='cardTitle'>mainAxisSize</APText>
                        <APSwitcher width="100%" options={['min', 'max'] as MainAxisSize[]} onSelect={setMainAxisSize}></APSwitcher>
                        <APText variant='cardTitle'>mainAxisAlignment</APText>
                        <APSwitcher width="100%" options={['spaceAround', 'spaceBetween', 'spaceEvenly', 'start', 'end', 'center'] as MainAxisAlignment[]} onSelect={setMainAxisAlignment}></APSwitcher>
                        <APText variant='cardTitle'>crossAxisAlignment</APText>
                        <APSwitcher width="100%" options={['center', 'start', 'end', 'stretch', 'baseline'] as CrossAxisAlignment[]} onSelect={setCrossAxisAlignment}></APSwitcher>
                    </APColumn>
                </APExpanded>
                <APExpanded flex={1.5} noClone>
                    <APColumn mainAxisSize={mainAxisSize} mainAxisAlignment={mainAxisAlignment} crossAxisAlignment={crossAxisAlignment}>
                        <Square></Square>
                        <Square></Square>
                        <Square></Square>
                        <Square></Square>
                    </APColumn>
                </APExpanded>
            </APRow>
        </APExpanded>
    </DemoSection>
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function Square({ size = 40 }: { size?: number }) {
    return <div style={{ minHeight: `${size}px`, minWidth: `${size}px`, background: getRandomColor() }}></div>
}
