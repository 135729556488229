import { Popover } from "@mui/material";
import React, { useMemo, useState } from "react";
import { APColumn, APExpanded, APPadding, APRow, APVirtualList } from "../layout";
import { APDebounceText, APImage, APPalette, APText } from "..";
import AutoSizer from "react-virtualized-auto-sizer";
import Fuse from "fuse.js"

export interface APDropdownSearchItem<T> {
    /**
     *  Currently not supporting React.ReactNode as a label
     *  Reasons: 
     *  1. Need to figure out how to render it in the virtual list dynamically based on labelNode height
     *  2. Search Text will not work 
     */
    label: string
    value: T
}

export interface APDropdownSearchProps<T> {
    /**
     * @default "Select an Option"
     */
    label?: string
    initialValue?: T
    onChange?: (value: T) => void | Promise<void>
    items: APDropdownSearchItem<T>[]
    style?: React.CSSProperties
}

const MAX_POPOVER_HEIGHT = 200;
const DROPDOWN_ITEM_HEIGHT = 36;
const OVERFLOW_LENGTH = 8;

/**
 * @author `Abhishek Sinha`
 * @description A dropdown search component that can be used to select an option from a list of options, supports virtualization of dropdown options
 * 
 * Usage:
 * 
 * For Number dataType
 * ```tsx
 * <APDropdownSearch<number>
 * initialValue={1}
 * items={[
 * { label: "Option 1", value: 1 },
 * { label: "Option 2", value: 2 },
 * ]}
 * />
 * ```
 * For specific dataType
 * ```tsx
 * <APDropdownSearch<T>
 * initialValue={T}
 * items={[
 * { label: "Option 1", value: T },
 * { label: "Option 2", value: T },
 * ]}
 * />
 * ```
 * 
 * @param label Label to be shown on the dropdown button
 * @param initialValue Initial value to be shown on the dropdown button
 * @param items List of items to be shown in the dropdown
 * @param onChange Callback function to be called when an item is selected from the dropdown
 */
export default function APDropdownSearch<T>({ label = "Select an Option", initialValue, items, onChange, style }: APDropdownSearchProps<T>) {
    const [value, setValue] = useState(initialValue);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const [searchText, setSearchText] = useState<string>();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSearchText(undefined);
    };

    function handleItemClick(item: APDropdownSearchItem<T>) {
        setValue(item.value);
        onChange?.(item.value);
        handleClose();
    }

    const open = Boolean(anchorEl);

    const itemLabelAtIndex = items.findIndex((item) => item.value === value)

    // Scroll to a fixed height in the div
    // 34 represents the vertical padding of the popover
    let popOverHeight = Math.min((items.length * DROPDOWN_ITEM_HEIGHT) + 34, MAX_POPOVER_HEIGHT)
    if (items.length > OVERFLOW_LENGTH) {
        // 32 + 16 -> debounce + paddingBottom
        popOverHeight += 48;
    }

    let filteredItems = items;
    if (searchText) {
        filteredItems = new Fuse(items, fuseOptions).search(searchText);
    }

    const itemAtIndex = filteredItems.findIndex((item) => item.value === value)

    return (
        <div style={{ ...style, height: "42px", width: "100%" }}>
            <AutoSizer>
                {({ width: buttonWidth }: { width: number, height: number }) => {
                    return (
                        <>
                            <div
                                onMouseEnter={() => setIsButtonHovered(true)}
                                onMouseLeave={() => setIsButtonHovered(false)}
                                onClick={handleClick}
                                style={{ width: buttonWidth }}
                            >
                                <APRow
                                    style={{
                                        padding: "8px 12px",
                                        borderRadius: "20px",
                                        border: `1px solid ${itemAtIndex > -1 ? APPalette["brand-200"] : APPalette["grey-100"]}`,
                                        backgroundColor: itemAtIndex > -1 ? APPalette["brand-100"] : isButtonHovered ? APPalette["grey-100"] : "white",
                                    }}
                                    gap="8px"
                                    mainAxisAlignment="spaceBetween"
                                >
                                    <APText
                                        variant="paragraph-medium"
                                        color={itemAtIndex > -1 ? APPalette["brand-300"] : APPalette["grey-700"]}
                                        maxLines={1}
                                    >
                                        {itemLabelAtIndex > -1 ? items[itemLabelAtIndex].label : label}
                                    </APText>
                                    {
                                        open ?
                                            <APImage src={`/icons/icon-arrow-up${itemLabelAtIndex > -1 ? '-selected' : ''}.svg`} width="16px" height="16px" />
                                            :
                                            <APImage src={`/icons/icon-arrow-down${itemLabelAtIndex > -1 ? '-selected' : ''}.svg`} width="16px" height="16px" />
                                    }
                                </APRow>
                            </div>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <APColumn crossAxisAlignment="stretch" mainAxisSize="max" style={{ height: popOverHeight, width: buttonWidth, padding: "16px 0" }}>
                                    {
                                        items.length > OVERFLOW_LENGTH &&
                                        <APRow style={{ padding: "0 16px 8px" }}>
                                            <APExpanded>
                                                <APDebounceText initialValue={searchText} onChanged={setSearchText} />
                                            </APExpanded>
                                        </APRow>
                                    }
                                    <APExpanded>
                                        <APVirtualList
                                            initialScrollOffset={itemAtIndex * DROPDOWN_ITEM_HEIGHT}
                                            itemCount={filteredItems.length}
                                            itemHeight={DROPDOWN_ITEM_HEIGHT}
                                        >
                                            {({ index }) => {
                                                var item = filteredItems[index]
                                                return (
                                                    <DropdownItem
                                                        isActive={index === itemAtIndex}
                                                        label={item.label}
                                                        onSelect={(ev) => {
                                                            handleItemClick(item)
                                                        }}
                                                    />
                                                )
                                            }}
                                        </APVirtualList>
                                    </APExpanded>
                                </APColumn>
                            </Popover>
                        </>
                    )
                }}
            </AutoSizer>
        </div>
    )
}

function DropdownItem({ label, onSelect, isActive = false, style }: { label: string, onSelect: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void, isActive?: boolean, style?: React.CSSProperties }) {
    const [isDropdownItemHovered, setIsDropdownItemHovered] = useState(false)
    return (
        <div
            onMouseEnter={() => setIsDropdownItemHovered(true)}
            onMouseLeave={() => setIsDropdownItemHovered(false)}
            onClick={onSelect}
            style={{
                ...style,
                cursor: "pointer",
                backgroundColor: isActive ? APPalette["brand-100"] : isDropdownItemHovered ? APPalette["grey-100"] : "white",
                color: isActive ? APPalette["brand-300"] : "black"
            }}
        >
            <APPadding padding={"8px 16px"}>
                <APText variant="paragraph-medium" color="black" maxLines={1}>
                    {label}
                </APText>
            </APPadding>
        </div>
    )
}

const fuseOptions = {
    shouldSort: false,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0.0,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
        'label',
    ]
}