import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";


export function APDropdownIconMenu({
    children, options, initial, onSelect
}: {
    children: React.ReactElement;
    options: Array<string>;
    initial?: string;
    onSelect: (option: string) => any;
}) {

    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <IconButton onClick={(event) => setAnchorEl(event?.currentTarget)} size="large">
            {children}
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {options.map(option => {
                return <MenuItem key={option} onClick={() => {
                    onSelect(option);
                    handleClose();
                }}>{option}</MenuItem>;
            })}
        </Menu>
    </>;


}
