import { APRow } from "../layout";
import { APText } from "./APText";
import { APPalette } from "../utils";
import { useState } from "react";
import React from "react";

export interface APPillButtonProps {
    children: string,
    /**
     * Provide the icon alone, size and color will automatically be changed as per the component design
     */
    icon?: JSX.Element,
    onClick?: () => void | Promise<void>
}

export function APPillButton({ children, icon, onClick }: APPillButtonProps) {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div
            style={{
                backgroundColor: (onClick && isHovered) ? APPalette["grey-200"] : APPalette.white,
                borderRadius: "20px",
                padding: "8px",
                border: `1px solid ${APPalette["grey-200"]}`,
                cursor: onClick ? "pointer" : "auto",
                // This will disable the text selection upon spam clicking or double clicking
                userSelect: "none",
                opacity: onClick ? 1 : 0.5,
            }}
            onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (onClick) {
                    await onClick()
                }
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <APRow gap="4px" mainAxisSize="min">
                {icon && React.cloneElement(icon, { size: 16, color: APPalette["grey-600"] })}
                {children && <APText variant='paragraph-xsmall' color={APPalette["grey-600"]}>{children}</APText>}
            </APRow>
        </div>
    )
}