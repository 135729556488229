import React from 'react';
import { APRow } from '../layout';
import { APPalette } from '../utils';

interface APBadgeProps {
    content: React.ReactNode;
    /**
     * If the content is a number and it is greater than max then it will show max+ instead of the number
     */
    max?: number;
    /**
     * @default 'important' 
     * Default variant is 'important' for reverse compatibility
     */
    variant?: 'default' | 'important' | 'primary' | 'secondary'
}

export function APBadge({ content, max, variant = 'important' }: APBadgeProps) {
    if (!content) {
        return null
    }

    if (content && max && typeof content === 'number' && content > max) {
        content = max + "+"
    }

    let backgroundColor = APPalette['grey-200']
    let foregroundColor = APPalette['grey-700']

    switch (variant) {
        case 'important':
            backgroundColor = APPalette['negative-300']
            foregroundColor = APPalette.white
            break;
        case 'primary':
            backgroundColor = APPalette['brand-300']
            foregroundColor = APPalette.white
            break;
        case 'secondary':
            backgroundColor = APPalette.white
            foregroundColor = APPalette['brand-300']
            break;
    }

    return (
        <APRow
            style={{
                backgroundColor,
                height: "20px",
                minWidth: "20px",
                padding: "0 5px",
                borderRadius: "10px",
            }}
            mainAxisAlignment="center"
            mainAxisSize='min'
        >
            <span style={{
                color: foregroundColor,
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 600
            }}>{content}</span>
        </APRow>
    )
}
