import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { APPalette } from "../utils";
import { APButton, ButtonSize } from "../elements";
import { DownIcon, UpIcon } from "../icons";
import { PopoverOrigin } from "@mui/material";

export function APActionsMenu({ options, label, size = "small", direction = "down" }: { options: { label: string; callback: () => any; }[]; label: string; size?: ButtonSize, direction?: "up" | "down" }) {
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    let icon = <DownIcon color={APPalette["brand-300"]} size={16} />,
        anchorOrigin: PopoverOrigin = {
            vertical: "bottom",
            horizontal: "right"
        },
        transformOrigin: PopoverOrigin = {
            vertical: "top",
            horizontal: "center"
        };

    if (direction === "up") {
        icon = <UpIcon color={APPalette["brand-300"]} size={16} />;
        anchorOrigin = {
            vertical: "top", horizontal: "left"
        };
        transformOrigin = {
            vertical: "bottom",
            horizontal: "center"
        }
    }


    return <>
        <APButton
            size={size}
            type="secondary"
            leading={icon}
            onClick={(event) => {
                setAnchorEl(event.currentTarget);
            }}
        >
            {label}
        </APButton>
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {options.map(option => {
                return <MenuItem key={option.label} onClick={() => {
                    handleClose();
                    option.callback();
                }}>{option.label}</MenuItem>;
            })}
        </Menu>
    </>;
}
