import { APColumn, APSizedBox, APText, APCard, APPalette } from "ap-components";
import React from "react";
export function DemoPage({ label, children }: { label: string, children: any }) {
    return <APColumn>
        <APSizedBox height="18px" />
        <APText variant="h2"  >{label}</APText>
        <APSizedBox height="12px" />
        {children}
        <APSizedBox height="12px" />
    </APColumn>
}

export function DemoSection({ label, children, style, deprecated = false }: { label: string, children: any, style?: React.CSSProperties, deprecated?: boolean }) {
    return <APColumn mainAxisSize='min' mainAxisAlignment='start'>
        <APSizedBox height="32px" />
        <APText variant="h3" >{label} <span style={{ color: "red" }}>{deprecated ? "@deprecated" : ''}</span></APText>
        <APSizedBox height="18px" />
        <APCard style={{ width: "600px", ...style }}>
            <APColumn crossAxisAlignment='stretch' mainAxisSize="max"  >
                {children}
            </APColumn>
        </APCard>
    </APColumn>;
}

export function DemoItem({ label, children }: { label: string, children: any }) {
    return <APColumn mainAxisSize='min' crossAxisAlignment='start'>
        <APSizedBox height={"22px"}></APSizedBox>
        <APText variant='cardTitle'>{label}</APText>
        <APSizedBox height={"18px"}></APSizedBox>
        {children}
        <APSizedBox height={"22px"}></APSizedBox>
    </APColumn>
}