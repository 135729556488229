import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useEffect, useState } from "react";
import { APDebounceText, APExpanded, APVirtualList } from "../..";
import { APText } from "../../elements/APText";
import { APColumn } from "../../layout/APFlex";
import { HeaderItem, Option } from "./APFilterMenu";

export type FilterOptionSingle = {
    type: 'single';
    initialValue: string;
    options: Option[];
    onChange: (selected: string) => any;
};
export function APFilterSingle({ header, options, onChange, initialValue }: { initialValue?: string; header: string; options: Option[]; onChange: (v: string) => any; }) {

    const [selected, setSelected] = useState<string>(initialValue ? initialValue : options[0].value);

    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    useEffect(() => {
        if (initialValue)
            setSelected(initialValue);
    }, [initialValue]);

    var filteredOptions = options
        .filter(option => {
            if (searchText.length > 0) {
                return option.label.toLowerCase().includes(searchText.toLowerCase()) || option.value.toLowerCase().includes(searchText.toLowerCase());
            }
            return true;
        });

    return <APColumn mainAxisSize='max' crossAxisAlignment='stretch'>
        <HeaderItem text={header} />
        {options.length > 7 ? <APDebounceText style={{ marginLeft: "12px", marginRight: "12px", marginBottom: "6px", }} onChanged={(v) => { setSearchText(v) }} ></APDebounceText> : undefined}
        <APExpanded>
            <RadioGroup
                value={selected}
                onChange={(e, v) => {
                    setSelected(v);
                    e.stopPropagation();
                    e.preventDefault();
                }}>
                <APExpanded>
                    <APVirtualList itemCount={filteredOptions.length} itemHeight={36}>{
                        ({ index }) => {
                            var option = filteredOptions[index];
                            return <FormControlLabel
                                style={{ padding: "0 12px" }}
                                key={index}
                                value={option.value}
                                control={<Radio color='primary' size='small' />}
                                label={option.label} />
                        }
                    }</APVirtualList>
                </APExpanded>

            </RadioGroup>
        </APExpanded>
    </APColumn>;
}
