import { MouseEventHandler } from "react";
import APIconButton from "../icons/APIconButton";
import { APRow } from "../layout";
import { APPalette, APSpacing } from "../utils";
import { APText } from "./APText";
import { APIcon } from "../icons/APIcon";

export type APTagVariant = 'default' | 'info' | 'warning' | 'negative' | 'positive'

export interface APTagProps {
    /**
     * @deprecated
     * Use intent instead as per the new design guidelines
     * @default 'default'
     */
    variant?: 'default' | 'info' | 'warning' | 'negative' | 'positive'
    /**
     * @default 'subtle'
     */
    emphasis?: 'subtle' | 'intense'
    /**
     * @default 'default'
     */
    intent?: 'default' | 'info' | 'warning' | 'negative' | 'positive'
    /**
     * @default 'medium' 
     */
    size?: 'small' | 'medium' | 'large'
    /**
     * @deprecated
     * Border has been removed from this component
     */
    type?: 'default' | 'outlined'
    children: string
    onClose?: MouseEventHandler<HTMLDivElement>
    /**
     * @deprecated
     * Border has been removed from this component
     */
    noBorder?: boolean
    color?: string
    backgroundColor?: string
}

export function APTag({
    variant = "default",
    noBorder = false,
    intent = "default",
    emphasis = "subtle",
    size = "medium",
    children,
    onClose,
    color,
    backgroundColor,
}: APTagProps) {

    let _color,
        _backgroundColor,
        _iconSize,
        _fontVariant: "paragraph-xxsmall" | "paragraph-xsmall",
        _padding

    if (variant !== "default" && intent === "default") {
        intent = variant
    }

    switch (size) {
        case "small":
            _iconSize = 8
            _fontVariant = "paragraph-xxsmall"
            _padding = `${APSpacing[1]} ${APSpacing[2]}`
            break;
        case "large":
            _iconSize = 16
            _fontVariant = "paragraph-xsmall"
            _padding = `${APSpacing[2]} ${APSpacing[3]}`
            break;
        default:
            _iconSize = 16
            _fontVariant = "paragraph-xsmall"
            _padding = `${APSpacing[1]} ${APSpacing[2]}`
    }

    switch (intent) {
        case "info":
            _color = emphasis === "subtle" ? APPalette["info-400"] : APPalette["white"]
            _backgroundColor = emphasis === "subtle" ? APPalette["info-100"] : APPalette["info-400"]
            break;
        case "warning":
            _color = emphasis === "subtle" ? APPalette["warning-400"] : APPalette["white"]
            _backgroundColor = emphasis === "subtle" ? APPalette["warning-100"] : APPalette["warning-400"]
            break;
        case "negative":
            _color = emphasis === "subtle" ? APPalette["negative-400"] : APPalette["white"]
            _backgroundColor = emphasis === "subtle" ? APPalette["negative-100"] : APPalette["negative-400"]
            break;
        case "positive":
            _color = emphasis === "subtle" ? APPalette["positive-400"] : APPalette["white"]
            _backgroundColor = emphasis === "subtle" ? APPalette["positive-100"] : APPalette["positive-400"]
            break;
        default:
            _color = emphasis === "subtle" ? APPalette["grey-500"] : APPalette["white"]
            _backgroundColor = emphasis === "subtle" ? APPalette["grey-50"] : APPalette["grey-500"]
    }

    if (color) {
        _color = color
    }

    if (backgroundColor) {
        _backgroundColor = backgroundColor
    }

    return (
        <APRow
            gap="4px"
            mainAxisSize="min"
            style={{
                padding: _padding,
                backgroundColor: _backgroundColor,
                borderRadius: "4px",
            }}
        >
            <APText variant={_fontVariant} color={_color}>{children}</APText>
            {
                onClose && (
                    <APIconButton
                        hoverColor={APPalette["grey-300"]}
                        onClick={onClose}
                    >
                        <APIcon
                            icon="close"
                            size={_iconSize}
                            color={APPalette["grey-500"]}
                        />
                    </APIconButton>
                )
            }
        </APRow>
    )
}