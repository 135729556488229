import { APColumn, APExpanded, APFormFieldText, APJourneyApp, APPadding, APRow, REGEX, sleep } from 'ap-components'
import { DemoPage, DemoSection } from './Common'

export default function StepperAppPage() {


    return (
        <DemoPage label="Stepper App">
            <APPadding noClone>
                <APJourneyApp
                    style={{ width: "480px", height: "650px" }}
                    mode='linear'
                    title="Mutual Fund Activation"
                    description='Activate account to invest in Mutual Funds'
                    steps={[
                        {
                            title: "PAN Details",
                            description: "Investments will be linked to your PAN number",
                            form: <APColumn crossAxisAlignment='start'>
                                <APRow>
                                    <APFormFieldText label={"PAN Number"} validator={(v) => {
                                        if (!REGEX.PAN.test(v)) {
                                            return "Invalid PAN Number"
                                        }
                                    }} />
                                </APRow>

                            </APColumn>,
                            onSubmit: async () => {
                                console.log("Form 1 Submitted")
                            }
                        },
                        {
                            title: "Personal Details",
                            description: "Your personal details will be used for KYC and Folio Creation",
                            steps: [
                                {
                                    title: "Father Details",
                                    description: "Details of your father for folio",
                                    form: <div>Form 2.1</div>,
                                    onSubmit: async () => {
                                        console.log("Form 2.1 Submitted")
                                    }
                                },
                                {
                                    title: "Mother Details",
                                    description: "Details of your mother",
                                    form: <div>Form 2.2</div>,
                                    onSubmit: async () => {
                                        console.log("Form 2.2 Submitted")
                                    }
                                },
                            ],
                        }
                    ]}

                />
            </APPadding>
        </DemoPage>
    )
}
