import { FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { APColumn } from "../../layout";

export interface Option {
    /**
     * label of the option which should appear
     */
    label: string,
    /**
     * value of the option
     */
    value: string
}

export interface APFilterMultiDropdownProps {
    /**
     * initial selected value for the dropdown
     */
    initialValue?: string[];
    /**
     * array of options for the dropdown
     */
    options: Option[];
    /**
     * callback for changing selected value
     */
    onChange: (selected: string[], selectedWithLabel?: { label: string, value: string }[]) => any;
    /**
     * type of dropdown
     */
    type: 'multi';
}

export function APFilterMultiDropdown({ options, onChange, initialValue }: APFilterMultiDropdownProps) {

    const [selected, setSelected] = useState<string[]>(initialValue ?? []);
    const [selectedWithLabel, setSelectedWithLabel] = useState<{ label: string, value: string }[]>([]);

    useEffect(() => {
        onChange(selected, selectedWithLabel);
    }, [selected]);


    useEffect(() => {
        if (initialValue)
            setSelected(initialValue);
    }, [initialValue?.join(",") ?? ""]);

    return (
        <APColumn crossAxisAlignment="stretch" style={{ paddingBottom: "8px" }}>
            {
                options.map((option, index) => (
                    <FormControlLabel
                        style={{ padding: "0 22px" }}
                        key={index}
                        value={option.value}
                        checked={selected.includes(option.value)}
                        onChange={(e, v) => {
                            if (v) {
                                setSelected([...selected, option.value]);
                                setSelectedWithLabel([...selectedWithLabel, option]);
                            } else {
                                setSelected([...selected.filter(e => option.value !== e)]);
                                setSelectedWithLabel([...selectedWithLabel.filter(e => option.value !== e.value)]);
                            }
                        }}
                        control={<Checkbox color="primary" size='small' />}
                        label={option.label}
                    />
                ))
            }
        </APColumn>
    );
}