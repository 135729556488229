import MoreHoriz from "@mui/icons-material/MoreHoriz";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";




export function APMoreMenu({
    options, variant = 'medium', iconColor, type = 'horizontal'
}: {
    options: Array<{ label: string; callback: () => any; }>;
    variant?: 'medium' | 'large';
    iconColor?: string;
    type?: 'horizontal' | 'vertical'
}) {

    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>

        <IconButton size="small" onClick={(event) => {
            setAnchorEl(event.currentTarget);
        }} style={{ padding: 0 }} >
            {type === "horizontal" ?
                <MoreHoriz fontSize={variant} style={{ color: iconColor }}></MoreHoriz> :
                <MoreVertOutlinedIcon fontSize={variant} style={{ color: iconColor }}></MoreVertOutlinedIcon>
            }
        </IconButton>
        {/* <APIcon iconName="ic_action_more_horizontal" size={20} color="#b2b2b2" onClick={(event) => {
            setAnchorEl(event.currentTarget);
        }} /> */}
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {options.map(option => {
                return <MenuItem key={option.label} onClick={() => {
                    handleClose();
                    option.callback();
                }}>{option.label}</MenuItem>;
            })}
        </Menu>
    </>;
}
