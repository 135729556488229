export class APSpacing {
    static "0" = "0px";
    static "1" = "2px";
    static "2" = "4px";
    static "3" = "8px";
    static "4" = "12px";
    static "5" = "16px";
    static "6" = "24px";
    static "7" = "32px";
    static "8" = "40px";
    static "9" = "48px";
    static "10" = "56px";
    static "11" = "64px";
}