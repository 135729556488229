import { APFormFieldCheckbox, APFormFieldDateTime, APFormFieldDropdown, APFormFieldFile, APFormFieldImage, APFormFieldRadio, APFormFieldText, APOtp, APRow } from "ap-components";
import { DemoItem, DemoPage, DemoSection } from "./Common";
import moment, { Moment } from "moment";
import APSwitch from "ap-components/src/elements/APSwitch";
import APRadioRider from "ap-components/src/forms/APRadioRider";
import { APCheckboxRider } from "ap-components/src/forms/APCheckboxRider";

export default function InputPage() {
    return (
        <DemoPage label="Inputs">
            <DemoSection label="Input Field">
                <APFormFieldText
                    label='Plain Text'
                    helperText='This is a plain text input'
                />
                <APFormFieldText
                    label='Read Only Text'
                    readOnly
                    initialValue="You can only read me"
                    helperText='This is a disabled text input'
                />
                <APFormFieldText
                    label='Disabled Text'
                    disabled
                    initialValue="You can't modify me"
                    helperText='This is a disabled text input'
                />
                <APFormFieldText
                    label='Obscure Text'
                    obscureText
                    helperText='This is an obscure text input'
                />
                <APFormFieldText
                    initialValue="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt quas eaque exercitationem culpa vitae amet aliquam quaerat, iusto ipsa quibusdam corrupti enim rem at laboriosam inventore eveniet aliquid provident qui!"
                    placeholder='This is a Text Area'
                    multiline
                    onChanged={() => { }}
                />

                <APFormFieldText
                    label="Multiline"
                    initialValue="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt quas eaque exercitationem culpa vitae amet aliquam quaerat, iusto ipsa quibusdam corrupti enim rem at laboriosam inventore eveniet aliquid provident qui!"
                    placeholder='This is a Text Area'
                    multiline
                    onChanged={() => { }}
                />
            </DemoSection>

            <DemoSection label="Dropdown Field">
                <APFormFieldDropdown label="Dropdown"
                    onChanged={v => { }}
                    items={[
                        {
                            value: 1,
                            label: "Option 1"
                        },
                        {
                            value: 2,
                            label: "Option 2"
                        },
                        {
                            value: 3,
                            label: "Option 3"
                        },
                    ]}
                    helperText="This is a dropdown"
                />

                <APFormFieldDropdown
                    label="Disabled Dropdown"
                    initialValue={2}
                    disabled
                    items={[
                        {
                            value: 1,
                            label: "Option 1"
                        },
                        {
                            value: 2,
                            label: "Option 2"
                        },
                        {
                            value: 3,
                            label: "Option 3"
                        },
                    ]}
                    helperText="This is a disabled dropdown"
                />
            </DemoSection>

            <DemoSection label="Date and File">
                <DemoItem label="Date">
                    <APFormFieldDateTime
                        label="Select a date"
                        validator={(v) => {
                            if (v?.isBefore(moment())) {
                                return "Please select previous date";
                            }
                            return null;
                        }}
                        onChanged={function (v: Moment | null) {
                            console.log(v);
                        }}
                    />
                </DemoItem>

                <DemoItem label="Image">
                    <APFormFieldImage
                        label="Image"
                        validator={(v) => {
                            console.log(v)
                            if (!v || v.length === 0) {
                                return "Please select a image"
                            }
                            return null;
                        }}
                    />
                </DemoItem>

                <DemoItem label="File" >
                    <APFormFieldFile
                        label="File Input"
                        helperText="This is a file input"
                        onChanged={(v) => { console.log(v) }}
                        validator={(file, base64) => {
                            if (!file) return "Select file";
                            if (file.size > 1000) {
                                return "File size bigger than 1 KB";
                            }
                            return null;
                        }}
                    />
                </DemoItem>
            </DemoSection>

            <DemoSection label="Toggle">
                <DemoItem label="Radio">
                    <APFormFieldRadio label="Radio"
                        helperText="This is a radio group"
                        items={[
                            {
                                value: 1,
                                label: "Option 1",
                            },
                            {
                                value: 2,
                                label: "Option 2",
                            }
                        ]}
                        validator={(v) => {
                            if (v === 1) {
                                return "Don't select Option 1"
                            }
                            return null;
                        }}
                    />
                    <APFormFieldRadio
                        label="Disabled Radio"
                        disabled
                        helperText="This is a radio group"
                        items={[
                            {
                                value: 1,
                                label: "Option 1",
                            },
                            {
                                value: 2,
                                label: "Option 2",
                            }
                        ]}
                        validator={(v) => {
                            if (v === 1) {
                                return "Don't select Option 1"
                            }
                            return null;
                        }}
                    />
                </DemoItem>

                <DemoItem label="Checkbox">
                    <APFormFieldCheckbox label="Checkbox"
                        helperText="This is a checkbox"
                        onChanged={(v) => { console.log(v) }}
                    />
                </DemoItem>

                <DemoItem label="Switch">
                    <APRow gap="12px" mainAxisAlignment="center">
                        <APSwitch initialValue={true} onChanged={(val) => { }} />
                        <APSwitch onChanged={(val) => { }} />
                        <APSwitch initialValue={true} />
                        <APSwitch />
                    </APRow>
                </DemoItem>
            </DemoSection>

            <DemoSection label="OTP">
                <DemoItem label="4 digits">
                    <APOtp digits={4} helperText="Enter 4 digit OTP" />
                </DemoItem>

                <DemoItem label="6 digits">
                    <APOtp digits={6} error="Incorrect OTP" />
                </DemoItem>
            </DemoSection>



            <DemoSection label="APCheckbox">

                <APCheckboxRider
                    riders={[

                        {
                            label: "Checkbox 1",
                            value: 'Checkbox1',
                            description: "Description for Checkbox 1",
                            amount: 10,
                            dropDownItem: {

                                title: 'suminsured1',
                                initialValue: '7',
                                dropDownItem: [
                                    {
                                        label: 'checkboxdropdown7',
                                        value: '7'

                                    },
                                    {
                                        label: 'checkboxdropdown8',
                                        value: '8'

                                    }, {
                                        label: 'checkboxdropdown9',
                                        value: '9'

                                    }
                                ],
                            },

                        },

                        {
                            label: "Checkbox 2",
                            value: 'Checkbox2',
                            description: "Description for Checkbox 2",
                            amount: 100,


                        },
                        {
                            label: "Checkbox 3",
                            value: 'Checkbox3',
                            description: ["Non-payable items will be covered (as per list I, II, III, IV of Annexure I)", 'No impact on Booster+ if claim in a policy year is less than Rs 1,00,000', 'Consumer Price Index linked increase in base sum insured'],
                            amount: 1000,

                            dropDownItem: {

                                title: 'suminsured',
                                initialValue: '3',
                                dropDownItem: [
                                    {
                                        label: 'checkboxdropdown3',
                                        value: '3'

                                    },
                                    {
                                        label: 'checkboxdropdown4',
                                        value: '4'

                                    }, {
                                        label: 'checkboxdropdown5',
                                        value: '5'

                                    }
                                ],
                            },


                        },


                    ]}

                    onChanged={(v: any) => {
                        console.log(`value of check box is`)
                        console.log(typeof v);
                        console.log(v)

                    }}

                />

            </DemoSection>

            <DemoSection label="APRadio">
                <APRadioRider riders={[

                    {
                        label: "Radio 1",
                        value: 'Radio1',
                        description: "Description for Radio 1 Description for Radio 1",
                        dropDownItem: {

                            title: 'sumInsured',
                            initialValue: '3',
                            dropDownItem: [
                                {
                                    label: 'hello3',
                                    value: '3'

                                },
                                {
                                    label: 'hello4',
                                    value: '4'

                                }, {
                                    label: 'hello5',
                                    value: '5'

                                }
                            ],
                        },
                        amount: 1000000,

                    },

                    {
                        label: "Radio 2",
                        value: 'Radio2',
                        description: ["Non-payable items will be covered (as per list I, II, III, IV of Annexure I)", 'No impact on Booster+ if claim in a policy year is less than Rs 1,00,000', 'Consumer Price Index linked increase in base sum insured'],
                        dropDownItem: {

                            title: 'sumInsured',
                            initialValue: '0',
                            dropDownItem: [
                                {
                                    label: 'hello',
                                    value: '0'

                                },
                                {
                                    label: 'hello1',
                                    value: '1'

                                }, {
                                    label: 'hello2',
                                    value: '2'

                                }
                            ],



                        },

                        amount: 100000,

                    },


                    {
                        label: "Radio 3",
                        value: 'Radio3',
                        description: "Description for Radio 3",
                        amount: 1000,
                    },
                ]} onChanged={
                    (value: any) => {
                        console.log(value, 'value');


                    }
                } />

            </DemoSection>
        </DemoPage>
    )
}

