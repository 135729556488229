import MomentUtils from "@date-io/moment";
import SwapVertRounded from "@mui/icons-material/SwapVertRounded";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { launchDialog } from "../../dialogs/DialogHelper";
import { showErrorDialog } from "../../dialogs/showErrorDialog";
import { APDialogCard } from "../../elements/APDialogCard";
import { APText } from "../../elements/APText";
import { APColumn, APRow } from "../../layout/APFlex";
import { APPadding, APSizedBox } from "../../layout/Common";
import { HeaderItem } from "./APFilterMenu";
import { APFormFieldDateTime, APFormFieldText } from "../../forms";




export type APDateRange = { from?: string; to?: string; };
export type FilterOptionDate = {
    type: 'dateRange';
    initialValue: APDateRange;
    clearable?: boolean;
    onChange: (range: APDateRange) => any;
};



async function showDatePickerDialog({ label, initial, clearable }: { label?: string; initial?: string; clearable?: boolean; }) {

    var current = initial;

    return await launchDialog<string | undefined>((deferred) => {

        return <LocalizationProvider utils={MomentUtils} dateAdapter={MomentUtils}>
            <APDialogCard title={label ?? "Select Date"} style={{ minWidth: 0 }}>
                <APColumn crossAxisAlignment='stretch'>
                    <StaticDatePicker
                        // color='secondary' TODO: Changed in migration
                        // variant="static" //TODO: Changed in migration
                        // open={true}
                        value={moment(current, "YYYY-MM-DD")}
                        showToolbar={false}
                        onChange={(v) => {
                            if (v) {
                                console.log("New Value", v);
                                current = moment(v).format("YYYY-MM-DD");
                                //TODO: Not working after upgrade
                            }
                        }}
                        renderInput={function (props): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
                            return <TextField {...props}></TextField>;
                        }} />
                    <APSizedBox height="16px" />
                    <APRow mainAxisSize='max' mainAxisAlignment='spaceBetween' style={{ padding: "4px" }}>
                        {clearable
                            &&
                            <Button variant='text' color='primary' onClick={async () => {
                                deferred.resolve(undefined);
                            }}>CLEAR</Button>}
                        <Button variant='contained' color='primary' onClick={async () => {
                            deferred.resolve(current);
                        }}>SET</Button>

                    </APRow>

                </APColumn>
            </APDialogCard>
        </LocalizationProvider>;
    });
}
export function APFilterDate({ header, onChange, initialValue, clearable }: { initialValue?: APDateRange; header: string; clearable?: boolean; onChange: (v: APDateRange) => any; }) {

    const [selected, setSelected] = useState<APDateRange>(initialValue ?? {});

    useEffect(() => {
        onChange(selected);
    }, [selected]);


    useEffect(() => {
        if (initialValue)
            setSelected(initialValue);
    }, [initialValue?.from, initialValue?.to]);

    return <APColumn mainAxisSize='min' crossAxisAlignment='stretch'>
        <HeaderItem text={header} />
        <APPadding padding={"12px 12px 8px"}>
            <APColumn crossAxisAlignment={'center'} mainAxisSize='min' gap={"16px"}>

                <APFormFieldText
                    label="From Date"
                    keyboardType="date"
                    value={selected.from}
                    onChanged={(v) => {
                        setSelected({ from: moment(v).format("YYYY-MM-DD"), to: selected.to });

                    }}
                />

                <APFormFieldText
                    label="To Date"
                    keyboardType="date"
                    value={selected.to}
                    onChanged={(v) => {
                        setSelected({ from: selected.from, to: moment(v).format("YYYY-MM-DD") });
                    }}
                />
                {/* 
                <APColumn mainAxisSize='min' crossAxisAlignment='center' gap={"2px"} onClick={async () => {
                    try {
                        var currentDate = await showDatePickerDialog({
                            label: "From Date",
                            initial: selected.from,
                            clearable
                        });
                        setSelected({ from: currentDate, to: selected.to });
                    } catch (err) {
                        if (err instanceof Error)
                            showErrorDialog(err.message);
                    }
                }}>
                    <APText variant={'subtext'} style={{ fontSize: "12px" }}>From</APText>
                    <APText variant={selected.from ? undefined : 'subtext'}>{selected.from ? moment(selected.from).format("DD-MMM-YYYY") : "N/A"}</APText>
                </APColumn>
                <SwapVertRounded fontSize='small' htmlColor={"#888"} />
                <APColumn mainAxisSize='min' crossAxisAlignment='center' gap={"2px"}
                    onClick={async () => {
                        try {
                            var currentDate = await showDatePickerDialog({
                                label: "To Date",
                                initial: selected.to,
                                clearable
                            });
                            setSelected({ from: selected.from, to: currentDate });
                        } catch (err) {
                            if (err instanceof Error)
                                showErrorDialog(err.message);
                        }
                    }}>
                    <APText variant={'subtext'} style={{ fontSize: "12px" }}>To</APText>
                    <APText variant={selected.to ? undefined : 'subtext'}>{selected.to ? moment(selected.to).format("DD-MMM-YYYY") : "N/A"}</APText>
                </APColumn> */}
            </APColumn>
        </APPadding>
    </APColumn>;
}
