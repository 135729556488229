import SwapVertRounded from "@mui/icons-material/SwapVertRounded";
import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import { APText } from "../../elements";
import { APColumn, APPadding } from "../../layout";
import { HeaderItem } from "./APFilterMenu";

export type APAmountRange = { min?: number; max?: number; };

export type FilterOptionAmount = {
    type: 'amount';
    initialValue: APAmountRange;
    onChange: (range: APAmountRange) => any;
};

export function APFilterAmount({
    header,
    initialValue,
    onChange,
}: {
    header: string;
    initialValue?: APAmountRange;
    onChange: (v: APAmountRange) => any;
}) {

    const [value, setValue] = useState<APAmountRange>(initialValue ?? { min: 0, max: 0 });
    useEffect(() => {
        setValue(() => initialValue ?? { min: 0, max: 0 })
    }, [initialValue?.min, initialValue?.max])



    useEffect(() => {
        onChange(value);
    }, [value.min, value.max]);

    return (
        <APColumn mainAxisSize='min' crossAxisAlignment='stretch' >
            <HeaderItem text={header} />
            <APPadding padding={"4px 24px 12px"}>
                <APColumn crossAxisAlignment={'center'} mainAxisSize='min' gap={"16px"}>
                    <APColumn mainAxisSize='min' crossAxisAlignment='center' gap={"2px"}>
                        <APText variant={'subtext'} style={{ fontSize: "12px" }}>Min</APText>
                        <TextField
                            value={value.min !== undefined ? isNaN(value.min) ? "" : value.min.toString() : ""}
                            onChange={(event) => {
                                const num = parseFloat(event.currentTarget.value);
                                setValue(prev => ({ ...prev, min: num }))
                            }}
                        />
                    </APColumn>
                    <SwapVertRounded fontSize='small' htmlColor={"#888"} />
                    <APColumn mainAxisSize='min' crossAxisAlignment='center' gap={"2px"}>
                        <APText variant={'subtext'} style={{ fontSize: "12px" }}>Max</APText>
                        <TextField
                            value={value.max !== undefined ? isNaN(value.max) ? "" : value.max.toString() : ""}
                            onChange={(event) => {
                                const num = parseFloat(event.currentTarget.value);
                                setValue(prev => ({ ...prev, max: isNaN(num) ? undefined : num }));
                            }}
                        />
                    </APColumn>
                </APColumn>
            </APPadding>
        </APColumn>
    );
}
