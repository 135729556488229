import NotificationsOutlined from "@mui/icons-material/NotificationsOutlined";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { useSortState, APColumn, APText, APMoreMenu, APTable, showSnackbar, APDebounceText, APActionsMenu, APExpanded } from "ap-components";
import { DemoPage, DemoSection } from "./Common";
import sampleData from './sampleTableData.json';

export function DemoTable({ style }: { style?: React.CSSProperties }) {

    var sampleTableData: any[] = [];
    for (var i = 0; i < 10000; i++) {
        sampleTableData.push(...sampleData);
    }

    sampleTableData.length = 1;

    var [sortBy, sortDir, setSorting] = useSortState();

    const rowGetter = (index: number) => {
        var holding = sampleTableData[index];
        return {
            alarm: (
                <IconButton size='medium' disabled={!holding.alarms} onClick={async (e) => { }}>
                    <Badge
                        style={{ color: "#39F" }}
                        showZero={true}
                        badgeContent={
                            holding.alarms ?
                                <div style={{ fontWeight: 600, color: holding.alarms.length == 0 ? "rgba(0, 0, 0, 0.26)" : undefined }}>{holding.alarms.length}</div>
                                : undefined
                        }
                    >
                        <NotificationsOutlined fontSize='small' color={
                            (holding.alarms && holding.alarms.length > 0)
                                ? 'primary' : 'disabled'} />
                    </Badge>
                </IconButton>
            ),
            schemeName: (
                <APColumn crossAxisAlignment="stretch">
                    {/* <APInkWell onClick={() => launchScheme(holding.schemeCode)}> */}
                    <APText variant="paragraph-small">{holding.schemeName}</APText>
                    {/* </APInkWell> */}
                    {/* <div style={{ color: "#828282" }}>{`${holding.amfiBroad} - ${holding.amfiSub}`}</div> */}
                </APColumn>
            ),
            folioNo: <APText variant="paragraph-small">{holding.isAssetPlus ? "AssetPlus" : "Outside"}</APText>,
            totalInvestment: <APText variant="paragraph-small">{holding.totalInvestment.currency(1)}</APText>,
            totalUnits: <APText variant="paragraph-small">{holding.totalUnits.toFixed(3)}</APText>,
            growth: <APText variant="paragraph-small" colored={holding.growth} >{holding.growth.currency(1)}</APText>,
            assetValue: holding.assetValue.currency(1),
            xirrReturns: <APText variant="paragraph-small" colored={holding.xirrReturns}>{(holding.xirrReturns * 100).toFixed(2)}%</APText>,
            absoluteReturns: <APText variant="paragraph-small" colored={holding.absoluteReturns}>{(holding.absoluteReturns * 100).toFixed(2)}%</APText>,
            lockFreeValue: <APText variant="paragraph-small">{holding.lockFreeValue.currency(1)}</APText>,
            unrealizedSTCG: <APText variant="paragraph-small">{holding.unrealizedSTCG.currency(1)}</APText>,
            unrealizedLTCG: <APText variant="paragraph-small">{holding.unrealizedLTCG?.currency(1)}</APText>,
            divReinvested: <APText variant="paragraph-small">{holding.divReinvested.currency(1)}</APText>,
            divPaid: <APText variant="paragraph-small">{holding.divPaid.currency(1)}</APText>,
            more: <div onClick={e => {
                e.stopPropagation();
                e.preventDefault();
            }}>
                <APMoreMenu
                    options={holding.isAssetPlus ? [
                        { label: "Lumpsum", callback: async () => { } },
                        { label: "Redeem", callback: async () => { } },
                        { label: "Switch", callback: async () => { } },
                        { label: "STP", callback: async () => { } },
                        { label: "SWP", callback: async () => { } },
                        { label: "Transactions", callback: async () => { } },
                        {
                            label: "Statement",
                            callback: async () => {
                                try {
                                    // await launchDialog<void>((deferred) => <FolioStatement deferred={deferred} userId={props.userId!} amcCode={holding.amcCode} folios={holding.folios.map(folio => folio.folioNo)} />)
                                } catch (error) {
                                    // handleErrorMessage(error)
                                }
                            }
                        }
                    ] : [
                        { label: "Delete", callback: async () => { } },
                        { label: "Switch", callback: async () => { } },
                        { label: "Redeem", callback: async () => { } },
                        { label: "Transactions", callback: async () => { } },
                    ]
                    }
                />
            </div>
        }
    };

    const columns = [
        {
            width: 60,
            label: "",
            dataKey: 'alarm',
        },
        {
            width: 320,
            flex: 3,
            label: "Scheme Name",
            dataKey: 'schemeName',
            sortable: true,
        },
        {
            width: 140,
            label: "Folio",
            dataKey: 'folioNo',
        },
        {
            width: 140,
            flex: 1,
            label: "Investment",
            dataKey: 'totalInvestment',
            numeric: true,
            sortable: true,
        },
        {
            width: 140,
            flex: 1,
            label: "Units",
            dataKey: 'totalUnits',
            numeric: true,
            optional: true
        },
        {
            width: 140,
            flex: 1,
            label: "Gains",
            dataKey: 'growth',
            numeric: true,
            sortable: true,
        },
        {
            width: 140,
            flex: 1,
            label: "Total",
            dataKey: 'assetValue',
            numeric: true,
            sortable: true,
        },
        {
            width: 140,
            flex: 1,
            label: "Absolute",
            dataKey: 'absoluteReturns',
            numeric: true,
            sortable: true,
            optional: true
        }, {
            width: 140,
            flex: 1,
            label: "XIRR",
            dataKey: 'xirrReturns',
            numeric: true,
            sortable: true,
            optional: true
        },
        {
            width: 140,
            flex: 1,
            label: "Load Free",
            dataKey: 'lockFreeValue',
            numeric: true,
            optional: true,
            sortable: true,

        },
        {
            width: 140,
            flex: 1,
            label: "Unrealized STCG",
            dataKey: 'unrealizedSTCG',
            numeric: true,
            sortable: true,
            optional: true,
        },
        {
            width: 140,

            flex: 1,
            label: "Unrealized LTCG",
            dataKey: 'unrealizedLTCG',
            numeric: true,
            sortable: true,
            optional: true,
        },
        {
            width: 140,

            flex: 1,
            label: "Div Rein",
            dataKey: 'divReinvested',
            numeric: true,
            sortable: true,
            optional: true
        },
        {
            width: 140,

            flex: 1,
            label: "Div Paid",
            dataKey: 'divPaid',
            sortable: true,
            optional: true,
            numeric: true,
        },
        {
            width: 70,
            label: '',
            dataKey: 'more',
        },
    ];

    console.log("Parent re-rendered");

    sampleTableData = sampleTableData
        .sortDir(holding => {
            switch (sortBy) {
                case "schemeName": return holding.schemeName;
                case "totalInvestment": return holding.totalInvestment;
                case "totalUnits": return holding.totalUnits;
                case "growth": return holding.growth;
                case "assetValue": return holding.assetValue;
                case "xirrReturns": return holding.xirrReturns;
                case "absoluteReturns": return holding.absoluteReturns;
                case "lockFreeValue": return holding.lockFreeValue;
                case "unrealizedSTCG": return holding.unrealizedSTCG;
                case "unrealizedLTCG": return holding.unrealizedLTCG;
                case "divReinvested": return holding.divReinvested;
                case "divPaid": return holding.divPaid;
            }
        }, sortDir);

    console.log(sampleTableData);

    return <APTable
        style={{ ...style }}
        rowCount={sampleTableData.length}
        freezeColumns={2}
        rowGetter={rowGetter}
        columns={columns}
        sortBy={sortBy}
        sortDir={sortDir}
        onSort={setSorting}
        headerHeight={40}
        paddingRows={5}
        saveKey={"newKey"}
        rowHeight={70}
        onLoadMore={() => {
            console.log("Load More");
            showSnackbar(`Load More Data`, "info");
        }}
        onRowClick={(index) => {
            console.log("Row Click");
            showSnackbar(`Clicked Row ${index}`, "info");
        }}
        leading={<APDebounceText onChanged={() => { }}></APDebounceText>}
        trailing={
            <>
                <APActionsMenu options={[]} label={"Dropdown"} ></APActionsMenu>
                <APActionsMenu options={[]} label={"Dropdown"} ></APActionsMenu>
                <APActionsMenu options={[]} label={"Dropdown"} ></APActionsMenu>
            </>}
    />
}


export default function Tables() {


    return (
        <DemoPage label="Tables">

            <DemoSection label="Rounded Table" style={{ height: "550px", width: "800px" }}>
                <APExpanded >
                    <DemoTable />
                </APExpanded>
            </DemoSection>
        </DemoPage>
    )
}