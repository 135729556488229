export type APFormFieldItem = {
    validate: () => Promise<boolean>,
    reset: () => any,
    name?: string,
}

export type APFormControl = {
    validate: () => Promise<boolean>,
    fields: Set<APFormFieldItem>,
    submit: () => any,
    reset: () => any,
}

export class APFormFieldController {
    text?: string;
    onChange?: () => any

    getText() {
        return this.text
    }

    setText(text: string) {
        this.text = text
        if (this.onChange)
            this.onChange();
    }
}


