import { APCenter, APAsyncButton, showErrorDialog, APSizedBox, APVirtualList, APRow, APText } from "ap-components";
import { useState } from "react";
import { DemoPage, DemoSection } from "./Common";

export default function VirtualList() {

    // useState is similar to setState of flutter
    const [todos, setTodos] = useState<any[]>([]);

    return <DemoPage label="Virtual List">
        <DemoSection label="Service Locator (Making API Call)">

            <APCenter>
                <APAsyncButton
                    nonSubmit
                    onClick={async () => {
                        try {
                            var response = await fetch('https://jsonplaceholder.typicode.com/todos');

                            var todos = await response.json();
                            setTodos(todos);
                        } catch (error) {
                            if (error instanceof Error)
                                showErrorDialog(error.message);
                        }
                    }
                    }
                >
                    FETCH TODOS
                </APAsyncButton>
            </APCenter>
            {
                todos.length > 0 &&
                <>
                    <APSizedBox height="12px" />

                    {/* APVirtualList creates and DOM element which is shown and deletes it when it goes out of scope. Good for saving page memory */}
                    <APSizedBox height="200px">
                        <APVirtualList itemCount={todos.length} itemHeight={50}>
                            {({ index }) => {
                                var todo = todos[index];
                                return <APRow>
                                    <APText>{todo.id}</APText>
                                    <APSizedBox width="12px" />
                                    <APText>{todo.title}</APText>
                                </APRow>
                            }}
                        </APVirtualList>
                    </APSizedBox>
                </>
            }
        </DemoSection>

    </DemoPage>
}