import { Router } from '@gatsbyjs/reach-router';
import DemoPage from './Demo';
import Mobile from './Mobile';

export default function App() {
    return (
        <Router style={{ display: "flex", height: "100%" }}>
            <DemoPage path="*" />
            <DemoPage path="demo/*" />
            <Mobile path="mobile" />
        </Router>
    );
}