import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import React, { useEffect, useState } from "react";
import { APButton, APExpanded, APFilterAmount, APIcon, APPalette, APScrollView, APText, FilterOptionAmount, SVGIcon } from "../..";
import { APColumn, APRow } from "../../layout";
import { APFilterDate, FilterOptionDate } from "./APFilterDate";
import { APFilterMulti, FilterOptionMulti } from "./APFilterMulti";
import { APFilterRange, FilterOptionRange } from "./APFilterRange";
import { APFilterSingle, FilterOptionSingle } from "./APFilterSingle";
import APIconButton from "../../icons/APIconButton";
import APBadgeContainer from "../../elements/APBadgeContainer";

type FilterOption = { title: string } & (Omit<FilterOptionSingle, 'onChange'> | Omit<FilterOptionMulti, 'onChange'> | Omit<FilterOptionDate, 'onChange'> | Omit<FilterOptionRange, 'onChange'> | Omit<FilterOptionAmount, 'onChange'>);

export interface IAPFilterMenuV2 {
    style?: React.CSSProperties
    saveKey?: string,
    icon?: SVGIcon,
    label: string,
    options: FilterOption[],
    onChange: (values: FilterOption['initialValue'][]) => void
}

export function APFilterMenuV2({ options, icon, label, style, saveKey, onChange }: IAPFilterMenuV2) {
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [selectedTab, setSelectedTab] = useState(options[0].title);
    const [unChangedValues, setUnChangedValues] = useState(options.map(option => option.initialValue));
    const [selectedValue, setSelectedValue] = useState(options.map(option => option.initialValue));

    let isSelected = false

    const handleClose = () => setAnchorEl(null)

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /// This effect ensures that unsaved values are not shown next time the filter menu is shown
    useEffect(() => setUnChangedValues(selectedValue.map(e => e)), [open])

    useEffect(() => {
        if (saveKey) {
            const savedValues = localStorage.getItem("filter_menu_selected_" + saveKey);
            if (savedValues) {
                setUnChangedValues(JSON.parse(savedValues));
                setSelectedValue(JSON.parse(savedValues));
                onChange(JSON.parse(savedValues));
            }
        }
    }, [])

    options.forEach((option, index) => {
        switch (option.type) {
            case "single":
                isSelected = isSelected || option.initialValue !== selectedValue[index];
                break;
            case "multi":
                isSelected = isSelected || option.initialValue.sort().join(',') !== (selectedValue[index] as FilterOptionMulti['initialValue']).sort().join(',');
                break;
            case "range":
                isSelected = isSelected || option.initialValue.min !== (selectedValue[index] as FilterOptionRange['initialValue']).min || option.initialValue.max !== (selectedValue[index] as FilterOptionRange['initialValue']).max
                break;
            case "dateRange":
                isSelected = isSelected || option.initialValue.from !== (selectedValue[index] as FilterOptionDate['initialValue']).from || option.initialValue.to !== (selectedValue[index] as FilterOptionDate['initialValue']).to
                break;
            case "amount":
                isSelected = isSelected || option.initialValue.min !== (selectedValue[index] as FilterOptionAmount['initialValue']).min || option.initialValue.max !== (selectedValue[index] as FilterOptionAmount['initialValue']).max
                break;
        }
    })

    return <div style={{ ...style, color: "#666" }}>
        <APBadgeContainer
            type="dot"
            icon={
                <APButton
                    type={"secondary"}
                    size={"small"}
                    style={{
                        paddingRight: "8px",
                        paddingLeft: "8px"
                    }}
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                    }}
                >
                    {
                        icon &&
                        <APIcon color={APPalette["brand-300"]} icon={icon} size={13} />
                    }
                    <span style={{
                        height: "18px",
                        fontSize: "13px",
                        letterSpacing: "0.4px",
                        lineHeight: "18px",
                    }}>
                        {label}
                    </span>
                </APButton>
            }
            content={isSelected ? 1 : 0}
        />
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
        >
            <APColumn crossAxisAlignment="stretch" mainAxisSize="max" style={{ minWidth: 480, height: "500px" }}>
                <APRow style={{ padding: "16px" }} gap="16px">
                    <APExpanded>
                        <APText variant="h3">{label}</APText>
                    </APExpanded>
                    <APText
                        variant="link-medium"
                        color={APPalette["grey-600"]}
                        onClick={() => {
                            setUnChangedValues(options.map(option => option.initialValue))
                        }}
                    >
                        Reset
                    </APText>
                    <APIconButton onClick={handleClose} padding="4px">
                        <APIcon icon="close" size={16} color={APPalette["grey-600"]} />
                    </APIconButton>
                </APRow>
                <Divider></Divider>
                <APExpanded>
                    <APRow crossAxisAlignment="stretch">
                        <APScrollView style={{ width: 157 }}>
                            <APColumn crossAxisAlignment="stretch" gap="8px" style={{ padding: "16px" }}>
                                {
                                    options.map((option, index) => {
                                        let isTouched = false;
                                        switch (option.type) {
                                            case "single":
                                                isTouched = option.initialValue !== unChangedValues[index];
                                                break;
                                            case "multi":
                                                isTouched = option.initialValue.sort().join(',') !== (unChangedValues[index] as FilterOptionMulti['initialValue']).sort().join(',');
                                                break;
                                            case "range":
                                                isTouched = option.initialValue.min !== (unChangedValues[index] as FilterOptionRange['initialValue']).min || option.initialValue.max !== (unChangedValues[index] as FilterOptionRange['initialValue']).max
                                                break;
                                            case "dateRange":
                                                isTouched = option.initialValue.from !== (unChangedValues[index] as FilterOptionDate['initialValue']).from || option.initialValue.to !== (unChangedValues[index] as FilterOptionDate['initialValue']).to
                                                break;
                                            case "amount":
                                                isTouched = option.initialValue.min !== (unChangedValues[index] as FilterOptionAmount['initialValue']).min || option.initialValue.max !== (unChangedValues[index] as FilterOptionAmount['initialValue']).max
                                                break;
                                        }
                                        return <APRow key={index} onClick={() => setSelectedTab(option.title)} mainAxisSize="max" gap="8px"
                                            style={{
                                                padding: "8px",
                                                borderRadius: "8px",
                                                backgroundColor: selectedTab === option.title ? APPalette["brand-100"] : undefined,
                                                color: selectedTab === option.title ? undefined : APPalette["brand-300"]
                                            }}
                                        >
                                            <APExpanded>
                                                <APText variant="paragraph-small">{option.title}</APText>
                                            </APExpanded>
                                            {
                                                isTouched &&
                                                <div style={{ borderRadius: "8px", height: "6px", width: "6px", backgroundColor: APPalette["negative-300"] }} />
                                            }
                                        </APRow>
                                    })
                                }
                            </APColumn>
                        </APScrollView>
                        <Divider orientation="vertical" />
                        <APExpanded>
                            <APScrollView style={{ padding: "8px" }}>
                                {
                                    options
                                        .filter(option => option.title === selectedTab)
                                        .map(option => {
                                            switch (option.type) {
                                                case 'multi':
                                                    return <APFilterMulti
                                                        key={`multi-${option.title}`}
                                                        initialValue={unChangedValues[options.indexOf(option)] as FilterOptionMulti['initialValue']}
                                                        header={option.title}
                                                        options={option.options}
                                                        onChange={v => setUnChangedValues(unChangedValues.map((value, i) => i === options.indexOf(option) ? v : value))}
                                                    />
                                                case 'single':
                                                    return <APFilterSingle
                                                        key={`single-${option.title}`}
                                                        initialValue={unChangedValues[options.indexOf(option)] as FilterOptionSingle['initialValue']}
                                                        header={option.title}
                                                        options={option.options}
                                                        onChange={v => setUnChangedValues(unChangedValues.map((value, i) => i === options.indexOf(option) ? v : value))}
                                                    />
                                                case 'dateRange':
                                                    return <APFilterDate
                                                        key={`dateRange-${option.title}`}
                                                        initialValue={unChangedValues[options.indexOf(option)] as FilterOptionDate['initialValue']}
                                                        header={option.title}
                                                        clearable={option.clearable}
                                                        onChange={v => setUnChangedValues(unChangedValues.map((value, i) => i === options.indexOf(option) ? v : value))}
                                                    />
                                                case 'range':
                                                    return <APFilterRange
                                                        key={`range-${option.title}`}
                                                        initialValue={unChangedValues[options.indexOf(option)] as FilterOptionRange['initialValue']}
                                                        totalRange={option.totalRange}
                                                        header={option.title}
                                                        onChange={v => setUnChangedValues(unChangedValues.map((value, i) => i === options.indexOf(option) ? v : value))}
                                                    />
                                                case 'amount':
                                                    return <APFilterAmount
                                                        key={`amount-${option.title}`}
                                                        initialValue={unChangedValues[options.indexOf(option)] as FilterOptionAmount['initialValue']}
                                                        header={option.title}
                                                        onChange={v => setUnChangedValues(unChangedValues.map((value, i) => i === options.indexOf(option) ? v : value))}
                                                    />
                                                default: return <div>Invalid type</div>
                                            }
                                        })
                                }
                            </APScrollView>
                        </APExpanded>
                    </APRow>
                </APExpanded>
                <Divider></Divider>
                <APRow mainAxisAlignment="end" style={{ padding: "16px" }}>
                    <APButton
                        size="small"
                        onClick={() => {
                            setSelectedValue(unChangedValues);
                            onChange(unChangedValues);
                            if (saveKey) {
                                localStorage.setItem("filter_menu_selected_" + saveKey, JSON.stringify(unChangedValues));
                            }
                            handleClose();
                        }}
                    >
                        Apply
                    </APButton>
                </APRow>
            </APColumn>
        </Popover>
    </div>
}