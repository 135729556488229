import { BulbIcon, CalculatorIcon, DocumentIcon, FaqIcon, GridIcon, MarketingIcon, SettingsOutlineIcon, UserOutlineIcon, sleep } from 'ap-components'
import { DemoItem, DemoPage, DemoSection } from './Common'
import APSideNav from 'ap-components/src/elements/APSideNav'

export default function NavigationPage() {

    return (
        <DemoPage label="Navigation">
            <DemoSection label='SideNav'>
                <DemoItem label="SideNav" >
                    <APSideNav
                        initialValue={"calculator"}
                        menuItems={[
                            {
                                icon: <MarketingIcon />,
                                label: "Marketing very long name",
                                value: "marketing",
                                onClick: () => { console.log("Marketing tab clicked") }
                            },
                            {
                                icon: <BulbIcon />,
                                label: "Knowledge",
                                value: "knowledge",
                                onClick: () => { console.log("Knowledge tab clicked") },
                            },
                            {
                                icon: <CalculatorIcon />,
                                label: "Calculator",
                                value: "calculator",
                                onClick: () => { console.log("Calculator tab clicked") },
                            },
                            {
                                icon: <FaqIcon />,
                                label: "Biz Guru",
                                value: "bizguru",
                                onClick: () => { console.log("Biz Guru tab clicked") },
                            },
                            {
                                icon: <DocumentIcon />,
                                label: "Offline Forms",
                                value: "offlineforms",
                                onClick: () => { console.log("Offline Forms tab clicked") },
                            },
                        ]}
                        onChange={(value) => {
                            console.log("SideNav value changed to " + value)
                        }}
                    />
                </DemoItem>
                <DemoItem label="Sub-Options SideNav" >
                    <APSideNav
                        initialValue={"dashboard"}
                        menuItems={[
                            {
                                icon: <SettingsOutlineIcon />,
                                label: "Settings very long name",
                                value: "settings",
                                subItems: [
                                    { label: "Profile", value: "profile", onClick: () => { console.log("Profile tab clicked") } },
                                    { label: "Account", value: "account", onClick: () => { console.log("Account tab clicked") } },
                                ],
                                onClick: async () => {
                                    console.log("Going to sleep")
                                    await sleep(2000)
                                    console.log("Sleep over")
                                }
                            },
                            {
                                icon: <GridIcon />,
                                label: "Dashboard",
                                value: "dashboard",
                                onClick: () => { console.log("Dashboard tab clicked") }
                            },
                            {
                                icon: <UserOutlineIcon />,
                                label: "Users",
                                value: "users",
                                onClick: () => { console.log("Users tab clicked") }
                            },
                        ]}
                        onChange={(value) => {
                            console.log("SideNav value changed to " + value)
                        }}
                    />
                </DemoItem>
            </DemoSection>
        </DemoPage>
    )
}

